var cities = [
    { 'code': '110000', 'city': '北京市' },
    { 'code': '110100', 'city': '市辖区' },
    { 'code': '110101', 'city': '东城区' },
    { 'code': '110102', 'city': '西城区' },
    { 'code': '110105', 'city': '朝阳区' },
    { 'code': '110106', 'city': '丰台区' },
    { 'code': '110107', 'city': '石景山区' },
    { 'code': '110108', 'city': '海淀区' },
    { 'code': '110109', 'city': '门头沟区' },
    { 'code': '110111', 'city': '房山区' },
    { 'code': '110112', 'city': '通州区' },
    { 'code': '110113', 'city': '顺义区' },
    { 'code': '110114', 'city': '昌平区' },
    { 'code': '110115', 'city': '大兴区' },
    { 'code': '110116', 'city': '怀柔区' },
    { 'code': '110117', 'city': '平谷区' },
    { 'code': '110118', 'city': '密云区' },
    { 'code': '110119', 'city': '延庆区' },
    { 'code': '120000', 'city': '天津市' },
    { 'code': '120100', 'city': '市辖区' },
    { 'code': '120101', 'city': '和平区' },
    { 'code': '120102', 'city': '河东区' },
    { 'code': '120103', 'city': '河西区' },
    { 'code': '120104', 'city': '南开区' },
    { 'code': '120105', 'city': '河北区' },
    { 'code': '120106', 'city': '红桥区' },
    { 'code': '120110', 'city': '东丽区' },
    { 'code': '120111', 'city': '西青区' },
    { 'code': '120112', 'city': '津南区' },
    { 'code': '120113', 'city': '北辰区' },
    { 'code': '120114', 'city': '武清区' },
    { 'code': '120115', 'city': '宝坻区' },
    { 'code': '120116', 'city': '滨海新区' },
    { 'code': '120117', 'city': '宁河区' },
    { 'code': '120118', 'city': '静海区' },
    { 'code': '120119', 'city': '蓟州区' },
    { 'code': '130000', 'city': '河北省' },
    { 'code': '130100', 'city': '石家庄市' },
    { 'code': '130101', 'city': '市辖区' },
    { 'code': '130102', 'city': '长安区' },
    { 'code': '130104', 'city': '桥西区' },
    { 'code': '130105', 'city': '新华区' },
    { 'code': '130107', 'city': '井陉矿区' },
    { 'code': '130108', 'city': '裕华区' },
    { 'code': '130109', 'city': '藁城区' },
    { 'code': '130110', 'city': '鹿泉区' },
    { 'code': '130111', 'city': '栾城区' },
    { 'code': '130121', 'city': '井陉县' },
    { 'code': '130123', 'city': '正定县' },
    { 'code': '130125', 'city': '行唐县' },
    { 'code': '130126', 'city': '灵寿县' },
    { 'code': '130127', 'city': '高邑县' },
    { 'code': '130128', 'city': '深泽县' },
    { 'code': '130129', 'city': '赞皇县' },
    { 'code': '130130', 'city': '无极县' },
    { 'code': '130131', 'city': '平山县' },
    { 'code': '130132', 'city': '元氏县' },
    { 'code': '130133', 'city': '赵县' },
    { 'code': '130183', 'city': '晋州市' },
    { 'code': '130184', 'city': '新乐市' },
    { 'code': '130200', 'city': '唐山市' },
    { 'code': '130201', 'city': '市辖区' },
    { 'code': '130202', 'city': '路南区' },
    { 'code': '130203', 'city': '路北区' },
    { 'code': '130204', 'city': '古冶区' },
    { 'code': '130205', 'city': '开平区' },
    { 'code': '130207', 'city': '丰南区' },
    { 'code': '130208', 'city': '丰润区' },
    { 'code': '130209', 'city': '曹妃甸区' },
    { 'code': '130223', 'city': '滦县' },
    { 'code': '130224', 'city': '滦南县' },
    { 'code': '130225', 'city': '乐亭县' },
    { 'code': '130227', 'city': '迁西县' },
    { 'code': '130229', 'city': '玉田县' },
    { 'code': '130281', 'city': '遵化市' },
    { 'code': '130283', 'city': '迁安市' },
    { 'code': '130300', 'city': '秦皇岛市' },
    { 'code': '130301', 'city': '市辖区' },
    { 'code': '130302', 'city': '海港区' },
    { 'code': '130303', 'city': '山海关区' },
    { 'code': '130304', 'city': '北戴河区' },
    { 'code': '130306', 'city': '抚宁区' },
    { 'code': '130321', 'city': '青龙满族自治县' },
    { 'code': '130322', 'city': '昌黎县' },
    { 'code': '130324', 'city': '卢龙县' },
    { 'code': '130400', 'city': '邯郸市' },
    { 'code': '130401', 'city': '市辖区' },
    { 'code': '130402', 'city': '邯山区' },
    { 'code': '130403', 'city': '丛台区' },
    { 'code': '130404', 'city': '复兴区' },
    { 'code': '130406', 'city': '峰峰矿区' },
    { 'code': '130421', 'city': '邯郸县' },
    { 'code': '130423', 'city': '临漳县' },
    { 'code': '130424', 'city': '成安县' },
    { 'code': '130425', 'city': '大名县' },
    { 'code': '130426', 'city': '涉县' },
    { 'code': '130427', 'city': '磁县' },
    { 'code': '130428', 'city': '肥乡县' },
    { 'code': '130429', 'city': '永年县' },
    { 'code': '130430', 'city': '邱县' },
    { 'code': '130431', 'city': '鸡泽县' },
    { 'code': '130432', 'city': '广平县' },
    { 'code': '130433', 'city': '馆陶县' },
    { 'code': '130434', 'city': '魏县' },
    { 'code': '130435', 'city': '曲周县' },
    { 'code': '130481', 'city': '武安市' },
    { 'code': '130500', 'city': '邢台市' },
    { 'code': '130501', 'city': '市辖区' },
    { 'code': '130502', 'city': '桥东区' },
    { 'code': '130503', 'city': '桥西区' },
    { 'code': '130521', 'city': '邢台县' },
    { 'code': '130522', 'city': '临城县' },
    { 'code': '130523', 'city': '内丘县' },
    { 'code': '130524', 'city': '柏乡县' },
    { 'code': '130525', 'city': '隆尧县' },
    { 'code': '130526', 'city': '任县' },
    { 'code': '130527', 'city': '南和县' },
    { 'code': '130528', 'city': '宁晋县' },
    { 'code': '130529', 'city': '巨鹿县' },
    { 'code': '130530', 'city': '新河县' },
    { 'code': '130531', 'city': '广宗县' },
    { 'code': '130532', 'city': '平乡县' },
    { 'code': '130533', 'city': '威县' },
    { 'code': '130534', 'city': '清河县' },
    { 'code': '130535', 'city': '临西县' },
    { 'code': '130581', 'city': '南宫市' },
    { 'code': '130582', 'city': '沙河市' },
    { 'code': '130600', 'city': '保定市' },
    { 'code': '130601', 'city': '市辖区' },
    { 'code': '130602', 'city': '竞秀区' },
    { 'code': '130606', 'city': '莲池区' },
    { 'code': '130607', 'city': '满城区' },
    { 'code': '130608', 'city': '清苑区' },
    { 'code': '130609', 'city': '徐水区' },
    { 'code': '130623', 'city': '涞水县' },
    { 'code': '130624', 'city': '阜平县' },
    { 'code': '130626', 'city': '定兴县' },
    { 'code': '130627', 'city': '唐县' },
    { 'code': '130628', 'city': '高阳县' },
    { 'code': '130629', 'city': '容城县' },
    { 'code': '130630', 'city': '涞源县' },
    { 'code': '130631', 'city': '望都县' },
    { 'code': '130632', 'city': '安新县' },
    { 'code': '130633', 'city': '易县' },
    { 'code': '130634', 'city': '曲阳县' },
    { 'code': '130635', 'city': '蠡县' },
    { 'code': '130636', 'city': '顺平县' },
    { 'code': '130637', 'city': '博野县' },
    { 'code': '130638', 'city': '雄县' },
    { 'code': '130681', 'city': '涿州市' },
    { 'code': '130683', 'city': '安国市' },
    { 'code': '130684', 'city': '高碑店市' },
    { 'code': '130700', 'city': '张家口市' },
    { 'code': '130701', 'city': '市辖区' },
    { 'code': '130702', 'city': '桥东区' },
    { 'code': '130703', 'city': '桥西区' },
    { 'code': '130705', 'city': '宣化区' },
    { 'code': '130706', 'city': '下花园区' },
    { 'code': '130708', 'city': '万全区' },
    { 'code': '130709', 'city': '崇礼区' },
    { 'code': '130722', 'city': '张北县' },
    { 'code': '130723', 'city': '康保县' },
    { 'code': '130724', 'city': '沽源县' },
    { 'code': '130725', 'city': '尚义县' },
    { 'code': '130726', 'city': '蔚县' },
    { 'code': '130727', 'city': '阳原县' },
    { 'code': '130728', 'city': '怀安县' },
    { 'code': '130730', 'city': '怀来县' },
    { 'code': '130731', 'city': '涿鹿县' },
    { 'code': '130732', 'city': '赤城县' },
    { 'code': '130800', 'city': '承德市' },
    { 'code': '130801', 'city': '市辖区' },
    { 'code': '130802', 'city': '双桥区' },
    { 'code': '130803', 'city': '双滦区' },
    { 'code': '130804', 'city': '鹰手营子矿区' },
    { 'code': '130821', 'city': '承德县' },
    { 'code': '130822', 'city': '兴隆县' },
    { 'code': '130823', 'city': '平泉县' },
    { 'code': '130824', 'city': '滦平县' },
    { 'code': '130825', 'city': '隆化县' },
    { 'code': '130826', 'city': '丰宁满族自治县' },
    { 'code': '130827', 'city': '宽城满族自治县' },
    { 'code': '130828', 'city': '围场满族蒙古族自治县' },
    { 'code': '130900', 'city': '沧州市' },
    { 'code': '130901', 'city': '市辖区' },
    { 'code': '130902', 'city': '新华区' },
    { 'code': '130903', 'city': '运河区' },
    { 'code': '130921', 'city': '沧县' },
    { 'code': '130922', 'city': '青县' },
    { 'code': '130923', 'city': '东光县' },
    { 'code': '130924', 'city': '海兴县' },
    { 'code': '130925', 'city': '盐山县' },
    { 'code': '130926', 'city': '肃宁县' },
    { 'code': '130927', 'city': '南皮县' },
    { 'code': '130928', 'city': '吴桥县' },
    { 'code': '130929', 'city': '献县' },
    { 'code': '130930', 'city': '孟村回族自治县' },
    { 'code': '130981', 'city': '泊头市' },
    { 'code': '130982', 'city': '任丘市' },
    { 'code': '130983', 'city': '黄骅市' },
    { 'code': '130984', 'city': '河间市' },
    { 'code': '131000', 'city': '廊坊市' },
    { 'code': '131001', 'city': '市辖区' },
    { 'code': '131002', 'city': '安次区' },
    { 'code': '131003', 'city': '广阳区' },
    { 'code': '131022', 'city': '固安县' },
    { 'code': '131023', 'city': '永清县' },
    { 'code': '131024', 'city': '香河县' },
    { 'code': '131025', 'city': '大城县' },
    { 'code': '131026', 'city': '文安县' },
    { 'code': '131028', 'city': '大厂回族自治县' },
    { 'code': '131081', 'city': '霸州市' },
    { 'code': '131082', 'city': '三河市' },
    { 'code': '131100', 'city': '衡水市' },
    { 'code': '131101', 'city': '市辖区' },
    { 'code': '131102', 'city': '桃城区' },
    { 'code': '131103', 'city': '冀州区' },
    { 'code': '131121', 'city': '枣强县' },
    { 'code': '131122', 'city': '武邑县' },
    { 'code': '131123', 'city': '武强县' },
    { 'code': '131124', 'city': '饶阳县' },
    { 'code': '131125', 'city': '安平县' },
    { 'code': '131126', 'city': '故城县' },
    { 'code': '131127', 'city': '景县' },
    { 'code': '131128', 'city': '阜城县' },
    { 'code': '131182', 'city': '深州市' },
    { 'code': '139000', 'city': '省直辖县级行政区划' },
    { 'code': '139001', 'city': '定州市' },
    { 'code': '139002', 'city': '辛集市' },
    { 'code': '140000', 'city': '山西省' },
    { 'code': '140100', 'city': '太原市' },
    { 'code': '140101', 'city': '市辖区' },
    { 'code': '140105', 'city': '小店区' },
    { 'code': '140106', 'city': '迎泽区' },
    { 'code': '140107', 'city': '杏花岭区' },
    { 'code': '140108', 'city': '尖草坪区' },
    { 'code': '140109', 'city': '万柏林区' },
    { 'code': '140110', 'city': '晋源区' },
    { 'code': '140121', 'city': '清徐县' },
    { 'code': '140122', 'city': '阳曲县' },
    { 'code': '140123', 'city': '娄烦县' },
    { 'code': '140181', 'city': '古交市' },
    { 'code': '140200', 'city': '大同市' },
    { 'code': '140201', 'city': '市辖区' },
    { 'code': '140202', 'city': '城区' },
    { 'code': '140203', 'city': '矿区' },
    { 'code': '140211', 'city': '南郊区' },
    { 'code': '140212', 'city': '新荣区' },
    { 'code': '140221', 'city': '阳高县' },
    { 'code': '140222', 'city': '天镇县' },
    { 'code': '140223', 'city': '广灵县' },
    { 'code': '140224', 'city': '灵丘县' },
    { 'code': '140225', 'city': '浑源县' },
    { 'code': '140226', 'city': '左云县' },
    { 'code': '140227', 'city': '大同县' },
    { 'code': '140300', 'city': '阳泉市' },
    { 'code': '140301', 'city': '市辖区' },
    { 'code': '140302', 'city': '城区' },
    { 'code': '140303', 'city': '矿区' },
    { 'code': '140311', 'city': '郊区' },
    { 'code': '140321', 'city': '平定县' },
    { 'code': '140322', 'city': '盂县' },
    { 'code': '140400', 'city': '长治市' },
    { 'code': '140401', 'city': '市辖区' },
    { 'code': '140402', 'city': '城区' },
    { 'code': '140411', 'city': '郊区' },
    { 'code': '140421', 'city': '长治县' },
    { 'code': '140423', 'city': '襄垣县' },
    { 'code': '140424', 'city': '屯留县' },
    { 'code': '140425', 'city': '平顺县' },
    { 'code': '140426', 'city': '黎城县' },
    { 'code': '140427', 'city': '壶关县' },
    { 'code': '140428', 'city': '长子县' },
    { 'code': '140429', 'city': '武乡县' },
    { 'code': '140430', 'city': '沁县' },
    { 'code': '140431', 'city': '沁源县' },
    { 'code': '140481', 'city': '潞城市' },
    { 'code': '140500', 'city': '晋城市' },
    { 'code': '140501', 'city': '市辖区' },
    { 'code': '140502', 'city': '城区' },
    { 'code': '140521', 'city': '沁水县' },
    { 'code': '140522', 'city': '阳城县' },
    { 'code': '140524', 'city': '陵川县' },
    { 'code': '140525', 'city': '泽州县' },
    { 'code': '140581', 'city': '高平市' },
    { 'code': '140600', 'city': '朔州市' },
    { 'code': '140601', 'city': '市辖区' },
    { 'code': '140602', 'city': '朔城区' },
    { 'code': '140603', 'city': '平鲁区' },
    { 'code': '140621', 'city': '山阴县' },
    { 'code': '140622', 'city': '应县' },
    { 'code': '140623', 'city': '右玉县' },
    { 'code': '140624', 'city': '怀仁县' },
    { 'code': '140700', 'city': '晋中市' },
    { 'code': '140701', 'city': '市辖区' },
    { 'code': '140702', 'city': '榆次区' },
    { 'code': '140721', 'city': '榆社县' },
    { 'code': '140722', 'city': '左权县' },
    { 'code': '140723', 'city': '和顺县' },
    { 'code': '140724', 'city': '昔阳县' },
    { 'code': '140725', 'city': '寿阳县' },
    { 'code': '140726', 'city': '太谷县' },
    { 'code': '140727', 'city': '祁县' },
    { 'code': '140728', 'city': '平遥县' },
    { 'code': '140729', 'city': '灵石县' },
    { 'code': '140781', 'city': '介休市' },
    { 'code': '140800', 'city': '运城市' },
    { 'code': '140801', 'city': '市辖区' },
    { 'code': '140802', 'city': '盐湖区' },
    { 'code': '140821', 'city': '临猗县' },
    { 'code': '140822', 'city': '万荣县' },
    { 'code': '140823', 'city': '闻喜县' },
    { 'code': '140824', 'city': '稷山县' },
    { 'code': '140825', 'city': '新绛县' },
    { 'code': '140826', 'city': '绛县' },
    { 'code': '140827', 'city': '垣曲县' },
    { 'code': '140828', 'city': '夏县' },
    { 'code': '140829', 'city': '平陆县' },
    { 'code': '140830', 'city': '芮城县' },
    { 'code': '140881', 'city': '永济市' },
    { 'code': '140882', 'city': '河津市' },
    { 'code': '140900', 'city': '忻州市' },
    { 'code': '140901', 'city': '市辖区' },
    { 'code': '140902', 'city': '忻府区' },
    { 'code': '140921', 'city': '定襄县' },
    { 'code': '140922', 'city': '五台县' },
    { 'code': '140923', 'city': '代县' },
    { 'code': '140924', 'city': '繁峙县' },
    { 'code': '140925', 'city': '宁武县' },
    { 'code': '140926', 'city': '静乐县' },
    { 'code': '140927', 'city': '神池县' },
    { 'code': '140928', 'city': '五寨县' },
    { 'code': '140929', 'city': '岢岚县' },
    { 'code': '140930', 'city': '河曲县' },
    { 'code': '140931', 'city': '保德县' },
    { 'code': '140932', 'city': '偏关县' },
    { 'code': '140981', 'city': '原平市' },
    { 'code': '141000', 'city': '临汾市' },
    { 'code': '141001', 'city': '市辖区' },
    { 'code': '141002', 'city': '尧都区' },
    { 'code': '141021', 'city': '曲沃县' },
    { 'code': '141022', 'city': '翼城县' },
    { 'code': '141023', 'city': '襄汾县' },
    { 'code': '141024', 'city': '洪洞县' },
    { 'code': '141025', 'city': '古县' },
    { 'code': '141026', 'city': '安泽县' },
    { 'code': '141027', 'city': '浮山县' },
    { 'code': '141028', 'city': '吉县' },
    { 'code': '141029', 'city': '乡宁县' },
    { 'code': '141030', 'city': '大宁县' },
    { 'code': '141031', 'city': '隰县' },
    { 'code': '141032', 'city': '永和县' },
    { 'code': '141033', 'city': '蒲县' },
    { 'code': '141034', 'city': '汾西县' },
    { 'code': '141081', 'city': '侯马市' },
    { 'code': '141082', 'city': '霍州市' },
    { 'code': '141100', 'city': '吕梁市' },
    { 'code': '141101', 'city': '市辖区' },
    { 'code': '141102', 'city': '离石区' },
    { 'code': '141121', 'city': '文水县' },
    { 'code': '141122', 'city': '交城县' },
    { 'code': '141123', 'city': '兴县' },
    { 'code': '141124', 'city': '临县' },
    { 'code': '141125', 'city': '柳林县' },
    { 'code': '141126', 'city': '石楼县' },
    { 'code': '141127', 'city': '岚县' },
    { 'code': '141128', 'city': '方山县' },
    { 'code': '141129', 'city': '中阳县' },
    { 'code': '141130', 'city': '交口县' },
    { 'code': '141181', 'city': '孝义市' },
    { 'code': '141182', 'city': '汾阳市' },
    { 'code': '150000', 'city': '内蒙古自治区' },
    { 'code': '150100', 'city': '呼和浩特市' },
    { 'code': '150101', 'city': '市辖区' },
    { 'code': '150102', 'city': '新城区' },
    { 'code': '150103', 'city': '回民区' },
    { 'code': '150104', 'city': '玉泉区' },
    { 'code': '150105', 'city': '赛罕区' },
    { 'code': '150121', 'city': '土默特左旗' },
    { 'code': '150122', 'city': '托克托县' },
    { 'code': '150123', 'city': '和林格尔县' },
    { 'code': '150124', 'city': '清水河县' },
    { 'code': '150125', 'city': '武川县' },
    { 'code': '150200', 'city': '包头市' },
    { 'code': '150201', 'city': '市辖区' },
    { 'code': '150202', 'city': '东河区' },
    { 'code': '150203', 'city': '昆都仑区' },
    { 'code': '150204', 'city': '青山区' },
    { 'code': '150205', 'city': '石拐区' },
    { 'code': '150206', 'city': '白云鄂博矿区' },
    { 'code': '150207', 'city': '九原区' },
    { 'code': '150221', 'city': '土默特右旗' },
    { 'code': '150222', 'city': '固阳县' },
    { 'code': '150223', 'city': '达尔罕茂明安联合旗' },
    { 'code': '150300', 'city': '乌海市' },
    { 'code': '150301', 'city': '市辖区' },
    { 'code': '150302', 'city': '海勃湾区' },
    { 'code': '150303', 'city': '海南区' },
    { 'code': '150304', 'city': '乌达区' },
    { 'code': '150400', 'city': '赤峰市' },
    { 'code': '150401', 'city': '市辖区' },
    { 'code': '150402', 'city': '红山区' },
    { 'code': '150403', 'city': '元宝山区' },
    { 'code': '150404', 'city': '松山区' },
    { 'code': '150421', 'city': '阿鲁科尔沁旗' },
    { 'code': '150422', 'city': '巴林左旗' },
    { 'code': '150423', 'city': '巴林右旗' },
    { 'code': '150424', 'city': '林西县' },
    { 'code': '150425', 'city': '克什克腾旗' },
    { 'code': '150426', 'city': '翁牛特旗' },
    { 'code': '150428', 'city': '喀喇沁旗' },
    { 'code': '150429', 'city': '宁城县' },
    { 'code': '150430', 'city': '敖汉旗' },
    { 'code': '150500', 'city': '通辽市' },
    { 'code': '150501', 'city': '市辖区' },
    { 'code': '150502', 'city': '科尔沁区' },
    { 'code': '150521', 'city': '科尔沁左翼中旗' },
    { 'code': '150522', 'city': '科尔沁左翼后旗' },
    { 'code': '150523', 'city': '开鲁县' },
    { 'code': '150524', 'city': '库伦旗' },
    { 'code': '150525', 'city': '奈曼旗' },
    { 'code': '150526', 'city': '扎鲁特旗' },
    { 'code': '150581', 'city': '霍林郭勒市' },
    { 'code': '150600', 'city': '鄂尔多斯市' },
    { 'code': '150601', 'city': '市辖区' },
    { 'code': '150602', 'city': '东胜区' },
    { 'code': '150603', 'city': '康巴什区' },
    { 'code': '150621', 'city': '达拉特旗' },
    { 'code': '150622', 'city': '准格尔旗' },
    { 'code': '150623', 'city': '鄂托克前旗' },
    { 'code': '150624', 'city': '鄂托克旗' },
    { 'code': '150625', 'city': '杭锦旗' },
    { 'code': '150626', 'city': '乌审旗' },
    { 'code': '150627', 'city': '伊金霍洛旗' },
    { 'code': '150700', 'city': '呼伦贝尔市' },
    { 'code': '150701', 'city': '市辖区' },
    { 'code': '150702', 'city': '海拉尔区' },
    { 'code': '150703', 'city': '扎赉诺尔区' },
    { 'code': '150721', 'city': '阿荣旗' },
    { 'code': '150722', 'city': '莫力达瓦达斡尔族自治旗' },
    { 'code': '150723', 'city': '鄂伦春自治旗' },
    { 'code': '150724', 'city': '鄂温克族自治旗' },
    { 'code': '150725', 'city': '陈巴尔虎旗' },
    { 'code': '150726', 'city': '新巴尔虎左旗' },
    { 'code': '150727', 'city': '新巴尔虎右旗' },
    { 'code': '150781', 'city': '满洲里市' },
    { 'code': '150782', 'city': '牙克石市' },
    { 'code': '150783', 'city': '扎兰屯市' },
    { 'code': '150784', 'city': '额尔古纳市' },
    { 'code': '150785', 'city': '根河市' },
    { 'code': '150800', 'city': '巴彦淖尔市' },
    { 'code': '150801', 'city': '市辖区' },
    { 'code': '150802', 'city': '临河区' },
    { 'code': '150821', 'city': '五原县' },
    { 'code': '150822', 'city': '磴口县' },
    { 'code': '150823', 'city': '乌拉特前旗' },
    { 'code': '150824', 'city': '乌拉特中旗' },
    { 'code': '150825', 'city': '乌拉特后旗' },
    { 'code': '150826', 'city': '杭锦后旗' },
    { 'code': '150900', 'city': '乌兰察布市' },
    { 'code': '150901', 'city': '市辖区' },
    { 'code': '150902', 'city': '集宁区' },
    { 'code': '150921', 'city': '卓资县' },
    { 'code': '150922', 'city': '化德县' },
    { 'code': '150923', 'city': '商都县' },
    { 'code': '150924', 'city': '兴和县' },
    { 'code': '150925', 'city': '凉城县' },
    { 'code': '150926', 'city': '察哈尔右翼前旗' },
    { 'code': '150927', 'city': '察哈尔右翼中旗' },
    { 'code': '150928', 'city': '察哈尔右翼后旗' },
    { 'code': '150929', 'city': '四子王旗' },
    { 'code': '150981', 'city': '丰镇市' },
    { 'code': '152200', 'city': '兴安盟' },
    { 'code': '152201', 'city': '乌兰浩特市' },
    { 'code': '152202', 'city': '阿尔山市' },
    { 'code': '152221', 'city': '科尔沁右翼前旗' },
    { 'code': '152222', 'city': '科尔沁右翼中旗' },
    { 'code': '152223', 'city': '扎赉特旗' },
    { 'code': '152224', 'city': '突泉县' },
    { 'code': '152500', 'city': '锡林郭勒盟' },
    { 'code': '152501', 'city': '二连浩特市' },
    { 'code': '152502', 'city': '锡林浩特市' },
    { 'code': '152522', 'city': '阿巴嘎旗' },
    { 'code': '152523', 'city': '苏尼特左旗' },
    { 'code': '152524', 'city': '苏尼特右旗' },
    { 'code': '152525', 'city': '东乌珠穆沁旗' },
    { 'code': '152526', 'city': '西乌珠穆沁旗' },
    { 'code': '152527', 'city': '太仆寺旗' },
    { 'code': '152528', 'city': '镶黄旗' },
    { 'code': '152529', 'city': '正镶白旗' },
    { 'code': '152530', 'city': '正蓝旗' },
    { 'code': '152531', 'city': '多伦县' },
    { 'code': '152900', 'city': '阿拉善盟' },
    { 'code': '152921', 'city': '阿拉善左旗' },
    { 'code': '152922', 'city': '阿拉善右旗' },
    { 'code': '152923', 'city': '额济纳旗' },
    { 'code': '210000', 'city': '辽宁省' },
    { 'code': '210100', 'city': '沈阳市' },
    { 'code': '210101', 'city': '市辖区' },
    { 'code': '210102', 'city': '和平区' },
    { 'code': '210103', 'city': '沈河区' },
    { 'code': '210104', 'city': '大东区' },
    { 'code': '210105', 'city': '皇姑区' },
    { 'code': '210106', 'city': '铁西区' },
    { 'code': '210111', 'city': '苏家屯区' },
    { 'code': '210112', 'city': '浑南区' },
    { 'code': '210113', 'city': '沈北新区' },
    { 'code': '210114', 'city': '于洪区' },
    { 'code': '210115', 'city': '辽中区' },
    { 'code': '210123', 'city': '康平县' },
    { 'code': '210124', 'city': '法库县' },
    { 'code': '210181', 'city': '新民市' },
    { 'code': '210200', 'city': '大连市' },
    { 'code': '210201', 'city': '市辖区' },
    { 'code': '210202', 'city': '中山区' },
    { 'code': '210203', 'city': '西岗区' },
    { 'code': '210204', 'city': '沙河口区' },
    { 'code': '210211', 'city': '甘井子区' },
    { 'code': '210212', 'city': '旅顺口区' },
    { 'code': '210213', 'city': '金州区' },
    { 'code': '210214', 'city': '普兰店区' },
    { 'code': '210224', 'city': '长海县' },
    { 'code': '210281', 'city': '瓦房店市' },
    { 'code': '210283', 'city': '庄河市' },
    { 'code': '210300', 'city': '鞍山市' },
    { 'code': '210301', 'city': '市辖区' },
    { 'code': '210302', 'city': '铁东区' },
    { 'code': '210303', 'city': '铁西区' },
    { 'code': '210304', 'city': '立山区' },
    { 'code': '210311', 'city': '千山区' },
    { 'code': '210321', 'city': '台安县' },
    { 'code': '210323', 'city': '岫岩满族自治县' },
    { 'code': '210381', 'city': '海城市' },
    { 'code': '210400', 'city': '抚顺市' },
    { 'code': '210401', 'city': '市辖区' },
    { 'code': '210402', 'city': '新抚区' },
    { 'code': '210403', 'city': '东洲区' },
    { 'code': '210404', 'city': '望花区' },
    { 'code': '210411', 'city': '顺城区' },
    { 'code': '210421', 'city': '抚顺县' },
    { 'code': '210422', 'city': '新宾满族自治县' },
    { 'code': '210423', 'city': '清原满族自治县' },
    { 'code': '210500', 'city': '本溪市' },
    { 'code': '210501', 'city': '市辖区' },
    { 'code': '210502', 'city': '平山区' },
    { 'code': '210503', 'city': '溪湖区' },
    { 'code': '210504', 'city': '明山区' },
    { 'code': '210505', 'city': '南芬区' },
    { 'code': '210521', 'city': '本溪满族自治县' },
    { 'code': '210522', 'city': '桓仁满族自治县' },
    { 'code': '210600', 'city': '丹东市' },
    { 'code': '210601', 'city': '市辖区' },
    { 'code': '210602', 'city': '元宝区' },
    { 'code': '210603', 'city': '振兴区' },
    { 'code': '210604', 'city': '振安区' },
    { 'code': '210624', 'city': '宽甸满族自治县' },
    { 'code': '210681', 'city': '东港市' },
    { 'code': '210682', 'city': '凤城市' },
    { 'code': '210700', 'city': '锦州市' },
    { 'code': '210701', 'city': '市辖区' },
    { 'code': '210702', 'city': '古塔区' },
    { 'code': '210703', 'city': '凌河区' },
    { 'code': '210711', 'city': '太和区' },
    { 'code': '210726', 'city': '黑山县' },
    { 'code': '210727', 'city': '义县' },
    { 'code': '210781', 'city': '凌海市' },
    { 'code': '210782', 'city': '北镇市' },
    { 'code': '210800', 'city': '营口市' },
    { 'code': '210801', 'city': '市辖区' },
    { 'code': '210802', 'city': '站前区' },
    { 'code': '210803', 'city': '西市区' },
    { 'code': '210804', 'city': '鲅鱼圈区' },
    { 'code': '210811', 'city': '老边区' },
    { 'code': '210881', 'city': '盖州市' },
    { 'code': '210882', 'city': '大石桥市' },
    { 'code': '210900', 'city': '阜新市' },
    { 'code': '210901', 'city': '市辖区' },
    { 'code': '210902', 'city': '海州区' },
    { 'code': '210903', 'city': '新邱区' },
    { 'code': '210904', 'city': '太平区' },
    { 'code': '210905', 'city': '清河门区' },
    { 'code': '210911', 'city': '细河区' },
    { 'code': '210921', 'city': '阜新蒙古族自治县' },
    { 'code': '210922', 'city': '彰武县' },
    { 'code': '211000', 'city': '辽阳市' },
    { 'code': '211001', 'city': '市辖区' },
    { 'code': '211002', 'city': '白塔区' },
    { 'code': '211003', 'city': '文圣区' },
    { 'code': '211004', 'city': '宏伟区' },
    { 'code': '211005', 'city': '弓长岭区' },
    { 'code': '211011', 'city': '太子河区' },
    { 'code': '211021', 'city': '辽阳县' },
    { 'code': '211081', 'city': '灯塔市' },
    { 'code': '211100', 'city': '盘锦市' },
    { 'code': '211101', 'city': '市辖区' },
    { 'code': '211102', 'city': '双台子区' },
    { 'code': '211103', 'city': '兴隆台区' },
    { 'code': '211104', 'city': '大洼区' },
    { 'code': '211122', 'city': '盘山县' },
    { 'code': '211200', 'city': '铁岭市' },
    { 'code': '211201', 'city': '市辖区' },
    { 'code': '211202', 'city': '银州区' },
    { 'code': '211204', 'city': '清河区' },
    { 'code': '211221', 'city': '铁岭县' },
    { 'code': '211223', 'city': '西丰县' },
    { 'code': '211224', 'city': '昌图县' },
    { 'code': '211281', 'city': '调兵山市' },
    { 'code': '211282', 'city': '开原市' },
    { 'code': '211300', 'city': '朝阳市' },
    { 'code': '211301', 'city': '市辖区' },
    { 'code': '211302', 'city': '双塔区' },
    { 'code': '211303', 'city': '龙城区' },
    { 'code': '211321', 'city': '朝阳县' },
    { 'code': '211322', 'city': '建平县' },
    { 'code': '211324', 'city': '喀喇沁左翼蒙古族自治县' },
    { 'code': '211381', 'city': '北票市' },
    { 'code': '211382', 'city': '凌源市' },
    { 'code': '211400', 'city': '葫芦岛市' },
    { 'code': '211401', 'city': '市辖区' },
    { 'code': '211402', 'city': '连山区' },
    { 'code': '211403', 'city': '龙港区' },
    { 'code': '211404', 'city': '南票区' },
    { 'code': '211421', 'city': '绥中县' },
    { 'code': '211422', 'city': '建昌县' },
    { 'code': '211481', 'city': '兴城市' },
    { 'code': '220000', 'city': '吉林省' },
    { 'code': '220100', 'city': '长春市' },
    { 'code': '220101', 'city': '市辖区' },
    { 'code': '220102', 'city': '南关区' },
    { 'code': '220103', 'city': '宽城区' },
    { 'code': '220104', 'city': '朝阳区' },
    { 'code': '220105', 'city': '二道区' },
    { 'code': '220106', 'city': '绿园区' },
    { 'code': '220112', 'city': '双阳区' },
    { 'code': '220113', 'city': '九台区' },
    { 'code': '220122', 'city': '农安县' },
    { 'code': '220182', 'city': '榆树市' },
    { 'code': '220183', 'city': '德惠市' },
    { 'code': '220200', 'city': '吉林市' },
    { 'code': '220201', 'city': '市辖区' },
    { 'code': '220202', 'city': '昌邑区' },
    { 'code': '220203', 'city': '龙潭区' },
    { 'code': '220204', 'city': '船营区' },
    { 'code': '220211', 'city': '丰满区' },
    { 'code': '220221', 'city': '永吉县' },
    { 'code': '220281', 'city': '蛟河市' },
    { 'code': '220282', 'city': '桦甸市' },
    { 'code': '220283', 'city': '舒兰市' },
    { 'code': '220284', 'city': '磐石市' },
    { 'code': '220300', 'city': '四平市' },
    { 'code': '220301', 'city': '市辖区' },
    { 'code': '220302', 'city': '铁西区' },
    { 'code': '220303', 'city': '铁东区' },
    { 'code': '220322', 'city': '梨树县' },
    { 'code': '220323', 'city': '伊通满族自治县' },
    { 'code': '220381', 'city': '公主岭市' },
    { 'code': '220382', 'city': '双辽市' },
    { 'code': '220400', 'city': '辽源市' },
    { 'code': '220401', 'city': '市辖区' },
    { 'code': '220402', 'city': '龙山区' },
    { 'code': '220403', 'city': '西安区' },
    { 'code': '220421', 'city': '东丰县' },
    { 'code': '220422', 'city': '东辽县' },
    { 'code': '220500', 'city': '通化市' },
    { 'code': '220501', 'city': '市辖区' },
    { 'code': '220502', 'city': '东昌区' },
    { 'code': '220503', 'city': '二道江区' },
    { 'code': '220521', 'city': '通化县' },
    { 'code': '220523', 'city': '辉南县' },
    { 'code': '220524', 'city': '柳河县' },
    { 'code': '220581', 'city': '梅河口市' },
    { 'code': '220582', 'city': '集安市' },
    { 'code': '220600', 'city': '白山市' },
    { 'code': '220601', 'city': '市辖区' },
    { 'code': '220602', 'city': '浑江区' },
    { 'code': '220605', 'city': '江源区' },
    { 'code': '220621', 'city': '抚松县' },
    { 'code': '220622', 'city': '靖宇县' },
    { 'code': '220623', 'city': '长白朝鲜族自治县' },
    { 'code': '220681', 'city': '临江市' },
    { 'code': '220700', 'city': '松原市' },
    { 'code': '220701', 'city': '市辖区' },
    { 'code': '220702', 'city': '宁江区' },
    { 'code': '220721', 'city': '前郭尔罗斯蒙古族自治县' },
    { 'code': '220722', 'city': '长岭县' },
    { 'code': '220723', 'city': '乾安县' },
    { 'code': '220781', 'city': '扶余市' },
    { 'code': '220800', 'city': '白城市' },
    { 'code': '220801', 'city': '市辖区' },
    { 'code': '220802', 'city': '洮北区' },
    { 'code': '220821', 'city': '镇赉县' },
    { 'code': '220822', 'city': '通榆县' },
    { 'code': '220881', 'city': '洮南市' },
    { 'code': '220882', 'city': '大安市' },
    { 'code': '222400', 'city': '延边朝鲜族自治州' },
    { 'code': '222401', 'city': '延吉市' },
    { 'code': '222402', 'city': '图们市' },
    { 'code': '222403', 'city': '敦化市' },
    { 'code': '222404', 'city': '珲春市' },
    { 'code': '222405', 'city': '龙井市' },
    { 'code': '222406', 'city': '和龙市' },
    { 'code': '222424', 'city': '汪清县' },
    { 'code': '222426', 'city': '安图县' },
    { 'code': '230000', 'city': '黑龙江省' },
    { 'code': '230100', 'city': '哈尔滨市' },
    { 'code': '230101', 'city': '市辖区' },
    { 'code': '230102', 'city': '道里区' },
    { 'code': '230103', 'city': '南岗区' },
    { 'code': '230104', 'city': '道外区' },
    { 'code': '230108', 'city': '平房区' },
    { 'code': '230109', 'city': '松北区' },
    { 'code': '230110', 'city': '香坊区' },
    { 'code': '230111', 'city': '呼兰区' },
    { 'code': '230112', 'city': '阿城区' },
    { 'code': '230113', 'city': '双城区' },
    { 'code': '230123', 'city': '依兰县' },
    { 'code': '230124', 'city': '方正县' },
    { 'code': '230125', 'city': '宾县' },
    { 'code': '230126', 'city': '巴彦县' },
    { 'code': '230127', 'city': '木兰县' },
    { 'code': '230128', 'city': '通河县' },
    { 'code': '230129', 'city': '延寿县' },
    { 'code': '230183', 'city': '尚志市' },
    { 'code': '230184', 'city': '五常市' },
    { 'code': '230200', 'city': '齐齐哈尔市' },
    { 'code': '230201', 'city': '市辖区' },
    { 'code': '230202', 'city': '龙沙区' },
    { 'code': '230203', 'city': '建华区' },
    { 'code': '230204', 'city': '铁锋区' },
    { 'code': '230205', 'city': '昂昂溪区' },
    { 'code': '230206', 'city': '富拉尔基区' },
    { 'code': '230207', 'city': '碾子山区' },
    { 'code': '230208', 'city': '梅里斯达斡尔族区' },
    { 'code': '230221', 'city': '龙江县' },
    { 'code': '230223', 'city': '依安县' },
    { 'code': '230224', 'city': '泰来县' },
    { 'code': '230225', 'city': '甘南县' },
    { 'code': '230227', 'city': '富裕县' },
    { 'code': '230229', 'city': '克山县' },
    { 'code': '230230', 'city': '克东县' },
    { 'code': '230231', 'city': '拜泉县' },
    { 'code': '230281', 'city': '讷河市' },
    { 'code': '230300', 'city': '鸡西市' },
    { 'code': '230301', 'city': '市辖区' },
    { 'code': '230302', 'city': '鸡冠区' },
    { 'code': '230303', 'city': '恒山区' },
    { 'code': '230304', 'city': '滴道区' },
    { 'code': '230305', 'city': '梨树区' },
    { 'code': '230306', 'city': '城子河区' },
    { 'code': '230307', 'city': '麻山区' },
    { 'code': '230321', 'city': '鸡东县' },
    { 'code': '230381', 'city': '虎林市' },
    { 'code': '230382', 'city': '密山市' },
    { 'code': '230400', 'city': '鹤岗市' },
    { 'code': '230401', 'city': '市辖区' },
    { 'code': '230402', 'city': '向阳区' },
    { 'code': '230403', 'city': '工农区' },
    { 'code': '230404', 'city': '南山区' },
    { 'code': '230405', 'city': '兴安区' },
    { 'code': '230406', 'city': '东山区' },
    { 'code': '230407', 'city': '兴山区' },
    { 'code': '230421', 'city': '萝北县' },
    { 'code': '230422', 'city': '绥滨县' },
    { 'code': '230500', 'city': '双鸭山市' },
    { 'code': '230501', 'city': '市辖区' },
    { 'code': '230502', 'city': '尖山区' },
    { 'code': '230503', 'city': '岭东区' },
    { 'code': '230505', 'city': '四方台区' },
    { 'code': '230506', 'city': '宝山区' },
    { 'code': '230521', 'city': '集贤县' },
    { 'code': '230522', 'city': '友谊县' },
    { 'code': '230523', 'city': '宝清县' },
    { 'code': '230524', 'city': '饶河县' },
    { 'code': '230600', 'city': '大庆市' },
    { 'code': '230601', 'city': '市辖区' },
    { 'code': '230602', 'city': '萨尔图区' },
    { 'code': '230603', 'city': '龙凤区' },
    { 'code': '230604', 'city': '让胡路区' },
    { 'code': '230605', 'city': '红岗区' },
    { 'code': '230606', 'city': '大同区' },
    { 'code': '230621', 'city': '肇州县' },
    { 'code': '230622', 'city': '肇源县' },
    { 'code': '230623', 'city': '林甸县' },
    { 'code': '230624', 'city': '杜尔伯特蒙古族自治县' },
    { 'code': '230700', 'city': '伊春市' },
    { 'code': '230701', 'city': '市辖区' },
    { 'code': '230702', 'city': '伊春区' },
    { 'code': '230703', 'city': '南岔区' },
    { 'code': '230704', 'city': '友好区' },
    { 'code': '230705', 'city': '西林区' },
    { 'code': '230706', 'city': '翠峦区' },
    { 'code': '230707', 'city': '新青区' },
    { 'code': '230708', 'city': '美溪区' },
    { 'code': '230709', 'city': '金山屯区' },
    { 'code': '230710', 'city': '五营区' },
    { 'code': '230711', 'city': '乌马河区' },
    { 'code': '230712', 'city': '汤旺河区' },
    { 'code': '230713', 'city': '带岭区' },
    { 'code': '230714', 'city': '乌伊岭区' },
    { 'code': '230715', 'city': '红星区' },
    { 'code': '230716', 'city': '上甘岭区' },
    { 'code': '230722', 'city': '嘉荫县' },
    { 'code': '230781', 'city': '铁力市' },
    { 'code': '230800', 'city': '佳木斯市' },
    { 'code': '230801', 'city': '市辖区' },
    { 'code': '230803', 'city': '向阳区' },
    { 'code': '230804', 'city': '前进区' },
    { 'code': '230805', 'city': '东风区' },
    { 'code': '230811', 'city': '郊区' },
    { 'code': '230822', 'city': '桦南县' },
    { 'code': '230826', 'city': '桦川县' },
    { 'code': '230828', 'city': '汤原县' },
    { 'code': '230881', 'city': '同江市' },
    { 'code': '230882', 'city': '富锦市' },
    { 'code': '230883', 'city': '抚远市' },
    { 'code': '230900', 'city': '七台河市' },
    { 'code': '230901', 'city': '市辖区' },
    { 'code': '230902', 'city': '新兴区' },
    { 'code': '230903', 'city': '桃山区' },
    { 'code': '230904', 'city': '茄子河区' },
    { 'code': '230921', 'city': '勃利县' },
    { 'code': '231000', 'city': '牡丹江市' },
    { 'code': '231001', 'city': '市辖区' },
    { 'code': '231002', 'city': '东安区' },
    { 'code': '231003', 'city': '阳明区' },
    { 'code': '231004', 'city': '爱民区' },
    { 'code': '231005', 'city': '西安区' },
    { 'code': '231025', 'city': '林口县' },
    { 'code': '231081', 'city': '绥芬河市' },
    { 'code': '231083', 'city': '海林市' },
    { 'code': '231084', 'city': '宁安市' },
    { 'code': '231085', 'city': '穆棱市' },
    { 'code': '231086', 'city': '东宁市' },
    { 'code': '231100', 'city': '黑河市' },
    { 'code': '231101', 'city': '市辖区' },
    { 'code': '231102', 'city': '爱辉区' },
    { 'code': '231121', 'city': '嫩江县' },
    { 'code': '231123', 'city': '逊克县' },
    { 'code': '231124', 'city': '孙吴县' },
    { 'code': '231181', 'city': '北安市' },
    { 'code': '231182', 'city': '五大连池市' },
    { 'code': '231200', 'city': '绥化市' },
    { 'code': '231201', 'city': '市辖区' },
    { 'code': '231202', 'city': '北林区' },
    { 'code': '231221', 'city': '望奎县' },
    { 'code': '231222', 'city': '兰西县' },
    { 'code': '231223', 'city': '青冈县' },
    { 'code': '231224', 'city': '庆安县' },
    { 'code': '231225', 'city': '明水县' },
    { 'code': '231226', 'city': '绥棱县' },
    { 'code': '231281', 'city': '安达市' },
    { 'code': '231282', 'city': '肇东市' },
    { 'code': '231283', 'city': '海伦市' },
    { 'code': '232700', 'city': '大兴安岭地区' },
    { 'code': '232721', 'city': '呼玛县' },
    { 'code': '232722', 'city': '塔河县' },
    { 'code': '232723', 'city': '漠河县' },
    { 'code': '310000', 'city': '上海市' },
    { 'code': '310100', 'city': '市辖区' },
    { 'code': '310101', 'city': '黄浦区' },
    { 'code': '310104', 'city': '徐汇区' },
    { 'code': '310105', 'city': '长宁区' },
    { 'code': '310106', 'city': '静安区' },
    { 'code': '310107', 'city': '普陀区' },
    { 'code': '310109', 'city': '虹口区' },
    { 'code': '310110', 'city': '杨浦区' },
    { 'code': '310112', 'city': '闵行区' },
    { 'code': '310113', 'city': '宝山区' },
    { 'code': '310114', 'city': '嘉定区' },
    { 'code': '310115', 'city': '浦东新区' },
    { 'code': '310116', 'city': '金山区' },
    { 'code': '310117', 'city': '松江区' },
    { 'code': '310118', 'city': '青浦区' },
    { 'code': '310120', 'city': '奉贤区' },
    { 'code': '310151', 'city': '崇明区' },
    { 'code': '320000', 'city': '江苏省' },
    { 'code': '320100', 'city': '南京市' },
    { 'code': '320101', 'city': '市辖区' },
    { 'code': '320102', 'city': '玄武区' },
    { 'code': '320104', 'city': '秦淮区' },
    { 'code': '320105', 'city': '建邺区' },
    { 'code': '320106', 'city': '鼓楼区' },
    { 'code': '320111', 'city': '浦口区' },
    { 'code': '320113', 'city': '栖霞区' },
    { 'code': '320114', 'city': '雨花台区' },
    { 'code': '320115', 'city': '江宁区' },
    { 'code': '320116', 'city': '六合区' },
    { 'code': '320117', 'city': '溧水区' },
    { 'code': '320118', 'city': '高淳区' },
    { 'code': '320200', 'city': '无锡市' },
    { 'code': '320201', 'city': '市辖区' },
    { 'code': '320205', 'city': '锡山区' },
    { 'code': '320206', 'city': '惠山区' },
    { 'code': '320211', 'city': '滨湖区' },
    { 'code': '320213', 'city': '梁溪区' },
    { 'code': '320214', 'city': '新吴区' },
    { 'code': '320281', 'city': '江阴市' },
    { 'code': '320282', 'city': '宜兴市' },
    { 'code': '320300', 'city': '徐州市' },
    { 'code': '320301', 'city': '市辖区' },
    { 'code': '320302', 'city': '鼓楼区' },
    { 'code': '320303', 'city': '云龙区' },
    { 'code': '320305', 'city': '贾汪区' },
    { 'code': '320311', 'city': '泉山区' },
    { 'code': '320312', 'city': '铜山区' },
    { 'code': '320321', 'city': '丰县' },
    { 'code': '320322', 'city': '沛县' },
    { 'code': '320324', 'city': '睢宁县' },
    { 'code': '320381', 'city': '新沂市' },
    { 'code': '320382', 'city': '邳州市' },
    { 'code': '320400', 'city': '常州市' },
    { 'code': '320401', 'city': '市辖区' },
    { 'code': '320402', 'city': '天宁区' },
    { 'code': '320404', 'city': '钟楼区' },
    { 'code': '320411', 'city': '新北区' },
    { 'code': '320412', 'city': '武进区' },
    { 'code': '320413', 'city': '金坛区' },
    { 'code': '320481', 'city': '溧阳市' },
    { 'code': '320500', 'city': '苏州市' },
    { 'code': '320501', 'city': '市辖区' },
    { 'code': '320505', 'city': '虎丘区' },
    { 'code': '320506', 'city': '吴中区' },
    { 'code': '320507', 'city': '相城区' },
    { 'code': '320508', 'city': '姑苏区' },
    { 'code': '320509', 'city': '吴江区' },
    { 'code': '320581', 'city': '常熟市' },
    { 'code': '320582', 'city': '张家港市' },
    { 'code': '320583', 'city': '昆山市' },
    { 'code': '320585', 'city': '太仓市' },
    { 'code': '320600', 'city': '南通市' },
    { 'code': '320601', 'city': '市辖区' },
    { 'code': '320602', 'city': '崇川区' },
    { 'code': '320611', 'city': '港闸区' },
    { 'code': '320612', 'city': '通州区' },
    { 'code': '320621', 'city': '海安县' },
    { 'code': '320623', 'city': '如东县' },
    { 'code': '320681', 'city': '启东市' },
    { 'code': '320682', 'city': '如皋市' },
    { 'code': '320684', 'city': '海门市' },
    { 'code': '320700', 'city': '连云港市' },
    { 'code': '320701', 'city': '市辖区' },
    { 'code': '320703', 'city': '连云区' },
    { 'code': '320706', 'city': '海州区' },
    { 'code': '320707', 'city': '赣榆区' },
    { 'code': '320722', 'city': '东海县' },
    { 'code': '320723', 'city': '灌云县' },
    { 'code': '320724', 'city': '灌南县' },
    { 'code': '320800', 'city': '淮安市' },
    { 'code': '320801', 'city': '市辖区' },
    { 'code': '320803', 'city': '淮安区' },
    { 'code': '320804', 'city': '淮阴区' },
    { 'code': '320812', 'city': '清江浦区' },
    { 'code': '320813', 'city': '洪泽区' },
    { 'code': '320826', 'city': '涟水县' },
    { 'code': '320830', 'city': '盱眙县' },
    { 'code': '320831', 'city': '金湖县' },
    { 'code': '320900', 'city': '盐城市' },
    { 'code': '320901', 'city': '市辖区' },
    { 'code': '320902', 'city': '亭湖区' },
    { 'code': '320903', 'city': '盐都区' },
    { 'code': '320904', 'city': '大丰区' },
    { 'code': '320921', 'city': '响水县' },
    { 'code': '320922', 'city': '滨海县' },
    { 'code': '320923', 'city': '阜宁县' },
    { 'code': '320924', 'city': '射阳县' },
    { 'code': '320925', 'city': '建湖县' },
    { 'code': '320981', 'city': '东台市' },
    { 'code': '321000', 'city': '扬州市' },
    { 'code': '321001', 'city': '市辖区' },
    { 'code': '321002', 'city': '广陵区' },
    { 'code': '321003', 'city': '邗江区' },
    { 'code': '321012', 'city': '江都区' },
    { 'code': '321023', 'city': '宝应县' },
    { 'code': '321081', 'city': '仪征市' },
    { 'code': '321084', 'city': '高邮市' },
    { 'code': '321100', 'city': '镇江市' },
    { 'code': '321101', 'city': '市辖区' },
    { 'code': '321102', 'city': '京口区' },
    { 'code': '321111', 'city': '润州区' },
    { 'code': '321112', 'city': '丹徒区' },
    { 'code': '321181', 'city': '丹阳市' },
    { 'code': '321182', 'city': '扬中市' },
    { 'code': '321183', 'city': '句容市' },
    { 'code': '321200', 'city': '泰州市' },
    { 'code': '321201', 'city': '市辖区' },
    { 'code': '321202', 'city': '海陵区' },
    { 'code': '321203', 'city': '高港区' },
    { 'code': '321204', 'city': '姜堰区' },
    { 'code': '321281', 'city': '兴化市' },
    { 'code': '321282', 'city': '靖江市' },
    { 'code': '321283', 'city': '泰兴市' },
    { 'code': '321300', 'city': '宿迁市' },
    { 'code': '321301', 'city': '市辖区' },
    { 'code': '321302', 'city': '宿城区' },
    { 'code': '321311', 'city': '宿豫区' },
    { 'code': '321322', 'city': '沭阳县' },
    { 'code': '321323', 'city': '泗阳县' },
    { 'code': '321324', 'city': '泗洪县' },
    { 'code': '330000', 'city': '浙江省' },
    { 'code': '330100', 'city': '杭州市' },
    { 'code': '330101', 'city': '市辖区' },
    { 'code': '330102', 'city': '上城区' },
    { 'code': '330103', 'city': '下城区' },
    { 'code': '330104', 'city': '江干区' },
    { 'code': '330105', 'city': '拱墅区' },
    { 'code': '330106', 'city': '西湖区' },
    { 'code': '330108', 'city': '滨江区' },
    { 'code': '330109', 'city': '萧山区' },
    { 'code': '330110', 'city': '余杭区' },
    { 'code': '330111', 'city': '富阳区' },
    { 'code': '330122', 'city': '桐庐县' },
    { 'code': '330127', 'city': '淳安县' },
    { 'code': '330182', 'city': '建德市' },
    { 'code': '330185', 'city': '临安市' },
    { 'code': '330200', 'city': '宁波市' },
    { 'code': '330201', 'city': '市辖区' },
    { 'code': '330203', 'city': '海曙区' },
    { 'code': '330204', 'city': '江东区' },
    { 'code': '330205', 'city': '江北区' },
    { 'code': '330206', 'city': '北仑区' },
    { 'code': '330211', 'city': '镇海区' },
    { 'code': '330212', 'city': '鄞州区' },
    { 'code': '330225', 'city': '象山县' },
    { 'code': '330226', 'city': '宁海县' },
    { 'code': '330281', 'city': '余姚市' },
    { 'code': '330282', 'city': '慈溪市' },
    { 'code': '330283', 'city': '奉化市' },
    { 'code': '330300', 'city': '温州市' },
    { 'code': '330301', 'city': '市辖区' },
    { 'code': '330302', 'city': '鹿城区' },
    { 'code': '330303', 'city': '龙湾区' },
    { 'code': '330304', 'city': '瓯海区' },
    { 'code': '330305', 'city': '洞头区' },
    { 'code': '330324', 'city': '永嘉县' },
    { 'code': '330326', 'city': '平阳县' },
    { 'code': '330327', 'city': '苍南县' },
    { 'code': '330328', 'city': '文成县' },
    { 'code': '330329', 'city': '泰顺县' },
    { 'code': '330381', 'city': '瑞安市' },
    { 'code': '330382', 'city': '乐清市' },
    { 'code': '330400', 'city': '嘉兴市' },
    { 'code': '330401', 'city': '市辖区' },
    { 'code': '330402', 'city': '南湖区' },
    { 'code': '330411', 'city': '秀洲区' },
    { 'code': '330421', 'city': '嘉善县' },
    { 'code': '330424', 'city': '海盐县' },
    { 'code': '330481', 'city': '海宁市' },
    { 'code': '330482', 'city': '平湖市' },
    { 'code': '330483', 'city': '桐乡市' },
    { 'code': '330500', 'city': '湖州市' },
    { 'code': '330501', 'city': '市辖区' },
    { 'code': '330502', 'city': '吴兴区' },
    { 'code': '330503', 'city': '南浔区' },
    { 'code': '330521', 'city': '德清县' },
    { 'code': '330522', 'city': '长兴县' },
    { 'code': '330523', 'city': '安吉县' },
    { 'code': '330600', 'city': '绍兴市' },
    { 'code': '330601', 'city': '市辖区' },
    { 'code': '330602', 'city': '越城区' },
    { 'code': '330603', 'city': '柯桥区' },
    { 'code': '330604', 'city': '上虞区' },
    { 'code': '330624', 'city': '新昌县' },
    { 'code': '330681', 'city': '诸暨市' },
    { 'code': '330683', 'city': '嵊州市' },
    { 'code': '330700', 'city': '金华市' },
    { 'code': '330701', 'city': '市辖区' },
    { 'code': '330702', 'city': '婺城区' },
    { 'code': '330703', 'city': '金东区' },
    { 'code': '330723', 'city': '武义县' },
    { 'code': '330726', 'city': '浦江县' },
    { 'code': '330727', 'city': '磐安县' },
    { 'code': '330781', 'city': '兰溪市' },
    { 'code': '330782', 'city': '义乌市' },
    { 'code': '330783', 'city': '东阳市' },
    { 'code': '330784', 'city': '永康市' },
    { 'code': '330800', 'city': '衢州市' },
    { 'code': '330801', 'city': '市辖区' },
    { 'code': '330802', 'city': '柯城区' },
    { 'code': '330803', 'city': '衢江区' },
    { 'code': '330822', 'city': '常山县' },
    { 'code': '330824', 'city': '开化县' },
    { 'code': '330825', 'city': '龙游县' },
    { 'code': '330881', 'city': '江山市' },
    { 'code': '330900', 'city': '舟山市' },
    { 'code': '330901', 'city': '市辖区' },
    { 'code': '330902', 'city': '定海区' },
    { 'code': '330903', 'city': '普陀区' },
    { 'code': '330921', 'city': '岱山县' },
    { 'code': '330922', 'city': '嵊泗县' },
    { 'code': '331000', 'city': '台州市' },
    { 'code': '331001', 'city': '市辖区' },
    { 'code': '331002', 'city': '椒江区' },
    { 'code': '331003', 'city': '黄岩区' },
    { 'code': '331004', 'city': '路桥区' },
    { 'code': '331021', 'city': '玉环县' },
    { 'code': '331022', 'city': '三门县' },
    { 'code': '331023', 'city': '天台县' },
    { 'code': '331024', 'city': '仙居县' },
    { 'code': '331081', 'city': '温岭市' },
    { 'code': '331082', 'city': '临海市' },
    { 'code': '331100', 'city': '丽水市' },
    { 'code': '331101', 'city': '市辖区' },
    { 'code': '331102', 'city': '莲都区' },
    { 'code': '331121', 'city': '青田县' },
    { 'code': '331122', 'city': '缙云县' },
    { 'code': '331123', 'city': '遂昌县' },
    { 'code': '331124', 'city': '松阳县' },
    { 'code': '331125', 'city': '云和县' },
    { 'code': '331126', 'city': '庆元县' },
    { 'code': '331127', 'city': '景宁畲族自治县' },
    { 'code': '331181', 'city': '龙泉市' },
    { 'code': '340000', 'city': '安徽省' },
    { 'code': '340100', 'city': '合肥市' },
    { 'code': '340101', 'city': '市辖区' },
    { 'code': '340102', 'city': '瑶海区' },
    { 'code': '340103', 'city': '庐阳区' },
    { 'code': '340104', 'city': '蜀山区' },
    { 'code': '340111', 'city': '包河区' },
    { 'code': '340121', 'city': '长丰县' },
    { 'code': '340122', 'city': '肥东县' },
    { 'code': '340123', 'city': '肥西县' },
    { 'code': '340124', 'city': '庐江县' },
    { 'code': '340181', 'city': '巢湖市' },
    { 'code': '340200', 'city': '芜湖市' },
    { 'code': '340201', 'city': '市辖区' },
    { 'code': '340202', 'city': '镜湖区' },
    { 'code': '340203', 'city': '弋江区' },
    { 'code': '340207', 'city': '鸠江区' },
    { 'code': '340208', 'city': '三山区' },
    { 'code': '340221', 'city': '芜湖县' },
    { 'code': '340222', 'city': '繁昌县' },
    { 'code': '340223', 'city': '南陵县' },
    { 'code': '340225', 'city': '无为县' },
    { 'code': '340300', 'city': '蚌埠市' },
    { 'code': '340301', 'city': '市辖区' },
    { 'code': '340302', 'city': '龙子湖区' },
    { 'code': '340303', 'city': '蚌山区' },
    { 'code': '340304', 'city': '禹会区' },
    { 'code': '340311', 'city': '淮上区' },
    { 'code': '340321', 'city': '怀远县' },
    { 'code': '340322', 'city': '五河县' },
    { 'code': '340323', 'city': '固镇县' },
    { 'code': '340400', 'city': '淮南市' },
    { 'code': '340401', 'city': '市辖区' },
    { 'code': '340402', 'city': '大通区' },
    { 'code': '340403', 'city': '田家庵区' },
    { 'code': '340404', 'city': '谢家集区' },
    { 'code': '340405', 'city': '八公山区' },
    { 'code': '340406', 'city': '潘集区' },
    { 'code': '340421', 'city': '凤台县' },
    { 'code': '340422', 'city': '寿县' },
    { 'code': '340500', 'city': '马鞍山市' },
    { 'code': '340501', 'city': '市辖区' },
    { 'code': '340503', 'city': '花山区' },
    { 'code': '340504', 'city': '雨山区' },
    { 'code': '340506', 'city': '博望区' },
    { 'code': '340521', 'city': '当涂县' },
    { 'code': '340522', 'city': '含山县' },
    { 'code': '340523', 'city': '和县' },
    { 'code': '340600', 'city': '淮北市' },
    { 'code': '340601', 'city': '市辖区' },
    { 'code': '340602', 'city': '杜集区' },
    { 'code': '340603', 'city': '相山区' },
    { 'code': '340604', 'city': '烈山区' },
    { 'code': '340621', 'city': '濉溪县' },
    { 'code': '340700', 'city': '铜陵市' },
    { 'code': '340701', 'city': '市辖区' },
    { 'code': '340705', 'city': '铜官区' },
    { 'code': '340706', 'city': '义安区' },
    { 'code': '340711', 'city': '郊区' },
    { 'code': '340722', 'city': '枞阳县' },
    { 'code': '340800', 'city': '安庆市' },
    { 'code': '340801', 'city': '市辖区' },
    { 'code': '340802', 'city': '迎江区' },
    { 'code': '340803', 'city': '大观区' },
    { 'code': '340811', 'city': '宜秀区' },
    { 'code': '340822', 'city': '怀宁县' },
    { 'code': '340824', 'city': '潜山县' },
    { 'code': '340825', 'city': '太湖县' },
    { 'code': '340826', 'city': '宿松县' },
    { 'code': '340827', 'city': '望江县' },
    { 'code': '340828', 'city': '岳西县' },
    { 'code': '340881', 'city': '桐城市' },
    { 'code': '341000', 'city': '黄山市' },
    { 'code': '341001', 'city': '市辖区' },
    { 'code': '341002', 'city': '屯溪区' },
    { 'code': '341003', 'city': '黄山区' },
    { 'code': '341004', 'city': '徽州区' },
    { 'code': '341021', 'city': '歙县' },
    { 'code': '341022', 'city': '休宁县' },
    { 'code': '341023', 'city': '黟县' },
    { 'code': '341024', 'city': '祁门县' },
    { 'code': '341100', 'city': '滁州市' },
    { 'code': '341101', 'city': '市辖区' },
    { 'code': '341102', 'city': '琅琊区' },
    { 'code': '341103', 'city': '南谯区' },
    { 'code': '341122', 'city': '来安县' },
    { 'code': '341124', 'city': '全椒县' },
    { 'code': '341125', 'city': '定远县' },
    { 'code': '341126', 'city': '凤阳县' },
    { 'code': '341181', 'city': '天长市' },
    { 'code': '341182', 'city': '明光市' },
    { 'code': '341200', 'city': '阜阳市' },
    { 'code': '341201', 'city': '市辖区' },
    { 'code': '341202', 'city': '颍州区' },
    { 'code': '341203', 'city': '颍东区' },
    { 'code': '341204', 'city': '颍泉区' },
    { 'code': '341221', 'city': '临泉县' },
    { 'code': '341222', 'city': '太和县' },
    { 'code': '341225', 'city': '阜南县' },
    { 'code': '341226', 'city': '颍上县' },
    { 'code': '341282', 'city': '界首市' },
    { 'code': '341300', 'city': '宿州市' },
    { 'code': '341301', 'city': '市辖区' },
    { 'code': '341302', 'city': '埇桥区' },
    { 'code': '341321', 'city': '砀山县' },
    { 'code': '341322', 'city': '萧县' },
    { 'code': '341323', 'city': '灵璧县' },
    { 'code': '341324', 'city': '泗县' },
    { 'code': '341500', 'city': '六安市' },
    { 'code': '341501', 'city': '市辖区' },
    { 'code': '341502', 'city': '金安区' },
    { 'code': '341503', 'city': '裕安区' },
    { 'code': '341504', 'city': '叶集区' },
    { 'code': '341522', 'city': '霍邱县' },
    { 'code': '341523', 'city': '舒城县' },
    { 'code': '341524', 'city': '金寨县' },
    { 'code': '341525', 'city': '霍山县' },
    { 'code': '341600', 'city': '亳州市' },
    { 'code': '341601', 'city': '市辖区' },
    { 'code': '341602', 'city': '谯城区' },
    { 'code': '341621', 'city': '涡阳县' },
    { 'code': '341622', 'city': '蒙城县' },
    { 'code': '341623', 'city': '利辛县' },
    { 'code': '341700', 'city': '池州市' },
    { 'code': '341701', 'city': '市辖区' },
    { 'code': '341702', 'city': '贵池区' },
    { 'code': '341721', 'city': '东至县' },
    { 'code': '341722', 'city': '石台县' },
    { 'code': '341723', 'city': '青阳县' },
    { 'code': '341800', 'city': '宣城市' },
    { 'code': '341801', 'city': '市辖区' },
    { 'code': '341802', 'city': '宣州区' },
    { 'code': '341821', 'city': '郎溪县' },
    { 'code': '341822', 'city': '广德县' },
    { 'code': '341823', 'city': '泾县' },
    { 'code': '341824', 'city': '绩溪县' },
    { 'code': '341825', 'city': '旌德县' },
    { 'code': '341881', 'city': '宁国市' },
    { 'code': '350000', 'city': '福建省' },
    { 'code': '350100', 'city': '福州市' },
    { 'code': '350101', 'city': '市辖区' },
    { 'code': '350102', 'city': '鼓楼区' },
    { 'code': '350103', 'city': '台江区' },
    { 'code': '350104', 'city': '仓山区' },
    { 'code': '350105', 'city': '马尾区' },
    { 'code': '350111', 'city': '晋安区' },
    { 'code': '350121', 'city': '闽侯县' },
    { 'code': '350122', 'city': '连江县' },
    { 'code': '350123', 'city': '罗源县' },
    { 'code': '350124', 'city': '闽清县' },
    { 'code': '350125', 'city': '永泰县' },
    { 'code': '350128', 'city': '平潭县' },
    { 'code': '350181', 'city': '福清市' },
    { 'code': '350182', 'city': '长乐市' },
    { 'code': '350200', 'city': '厦门市' },
    { 'code': '350201', 'city': '市辖区' },
    { 'code': '350203', 'city': '思明区' },
    { 'code': '350205', 'city': '海沧区' },
    { 'code': '350206', 'city': '湖里区' },
    { 'code': '350211', 'city': '集美区' },
    { 'code': '350212', 'city': '同安区' },
    { 'code': '350213', 'city': '翔安区' },
    { 'code': '350300', 'city': '莆田市' },
    { 'code': '350301', 'city': '市辖区' },
    { 'code': '350302', 'city': '城厢区' },
    { 'code': '350303', 'city': '涵江区' },
    { 'code': '350304', 'city': '荔城区' },
    { 'code': '350305', 'city': '秀屿区' },
    { 'code': '350322', 'city': '仙游县' },
    { 'code': '350400', 'city': '三明市' },
    { 'code': '350401', 'city': '市辖区' },
    { 'code': '350402', 'city': '梅列区' },
    { 'code': '350403', 'city': '三元区' },
    { 'code': '350421', 'city': '明溪县' },
    { 'code': '350423', 'city': '清流县' },
    { 'code': '350424', 'city': '宁化县' },
    { 'code': '350425', 'city': '大田县' },
    { 'code': '350426', 'city': '尤溪县' },
    { 'code': '350427', 'city': '沙县' },
    { 'code': '350428', 'city': '将乐县' },
    { 'code': '350429', 'city': '泰宁县' },
    { 'code': '350430', 'city': '建宁县' },
    { 'code': '350481', 'city': '永安市' },
    { 'code': '350500', 'city': '泉州市' },
    { 'code': '350501', 'city': '市辖区' },
    { 'code': '350502', 'city': '鲤城区' },
    { 'code': '350503', 'city': '丰泽区' },
    { 'code': '350504', 'city': '洛江区' },
    { 'code': '350505', 'city': '泉港区' },
    { 'code': '350521', 'city': '惠安县' },
    { 'code': '350524', 'city': '安溪县' },
    { 'code': '350525', 'city': '永春县' },
    { 'code': '350526', 'city': '德化县' },
    { 'code': '350527', 'city': '金门县' },
    { 'code': '350581', 'city': '石狮市' },
    { 'code': '350582', 'city': '晋江市' },
    { 'code': '350583', 'city': '南安市' },
    { 'code': '350600', 'city': '漳州市' },
    { 'code': '350601', 'city': '市辖区' },
    { 'code': '350602', 'city': '芗城区' },
    { 'code': '350603', 'city': '龙文区' },
    { 'code': '350622', 'city': '云霄县' },
    { 'code': '350623', 'city': '漳浦县' },
    { 'code': '350624', 'city': '诏安县' },
    { 'code': '350625', 'city': '长泰县' },
    { 'code': '350626', 'city': '东山县' },
    { 'code': '350627', 'city': '南靖县' },
    { 'code': '350628', 'city': '平和县' },
    { 'code': '350629', 'city': '华安县' },
    { 'code': '350681', 'city': '龙海市' },
    { 'code': '350700', 'city': '南平市' },
    { 'code': '350701', 'city': '市辖区' },
    { 'code': '350702', 'city': '延平区' },
    { 'code': '350703', 'city': '建阳区' },
    { 'code': '350721', 'city': '顺昌县' },
    { 'code': '350722', 'city': '浦城县' },
    { 'code': '350723', 'city': '光泽县' },
    { 'code': '350724', 'city': '松溪县' },
    { 'code': '350725', 'city': '政和县' },
    { 'code': '350781', 'city': '邵武市' },
    { 'code': '350782', 'city': '武夷山市' },
    { 'code': '350783', 'city': '建瓯市' },
    { 'code': '350800', 'city': '龙岩市' },
    { 'code': '350801', 'city': '市辖区' },
    { 'code': '350802', 'city': '新罗区' },
    { 'code': '350803', 'city': '永定区' },
    { 'code': '350821', 'city': '长汀县' },
    { 'code': '350823', 'city': '上杭县' },
    { 'code': '350824', 'city': '武平县' },
    { 'code': '350825', 'city': '连城县' },
    { 'code': '350881', 'city': '漳平市' },
    { 'code': '350900', 'city': '宁德市' },
    { 'code': '350901', 'city': '市辖区' },
    { 'code': '350902', 'city': '蕉城区' },
    { 'code': '350921', 'city': '霞浦县' },
    { 'code': '350922', 'city': '古田县' },
    { 'code': '350923', 'city': '屏南县' },
    { 'code': '350924', 'city': '寿宁县' },
    { 'code': '350925', 'city': '周宁县' },
    { 'code': '350926', 'city': '柘荣县' },
    { 'code': '350981', 'city': '福安市' },
    { 'code': '350982', 'city': '福鼎市' },
    { 'code': '360000', 'city': '江西省' },
    { 'code': '360100', 'city': '南昌市' },
    { 'code': '360101', 'city': '市辖区' },
    { 'code': '360102', 'city': '东湖区' },
    { 'code': '360103', 'city': '西湖区' },
    { 'code': '360104', 'city': '青云谱区' },
    { 'code': '360105', 'city': '湾里区' },
    { 'code': '360111', 'city': '青山湖区' },
    { 'code': '360112', 'city': '新建区' },
    { 'code': '360121', 'city': '南昌县' },
    { 'code': '360123', 'city': '安义县' },
    { 'code': '360124', 'city': '进贤县' },
    { 'code': '360200', 'city': '景德镇市' },
    { 'code': '360201', 'city': '市辖区' },
    { 'code': '360202', 'city': '昌江区' },
    { 'code': '360203', 'city': '珠山区' },
    { 'code': '360222', 'city': '浮梁县' },
    { 'code': '360281', 'city': '乐平市' },
    { 'code': '360300', 'city': '萍乡市' },
    { 'code': '360301', 'city': '市辖区' },
    { 'code': '360302', 'city': '安源区' },
    { 'code': '360313', 'city': '湘东区' },
    { 'code': '360321', 'city': '莲花县' },
    { 'code': '360322', 'city': '上栗县' },
    { 'code': '360323', 'city': '芦溪县' },
    { 'code': '360400', 'city': '九江市' },
    { 'code': '360401', 'city': '市辖区' },
    { 'code': '360402', 'city': '濂溪区' },
    { 'code': '360403', 'city': '浔阳区' },
    { 'code': '360421', 'city': '九江县' },
    { 'code': '360423', 'city': '武宁县' },
    { 'code': '360424', 'city': '修水县' },
    { 'code': '360425', 'city': '永修县' },
    { 'code': '360426', 'city': '德安县' },
    { 'code': '360428', 'city': '都昌县' },
    { 'code': '360429', 'city': '湖口县' },
    { 'code': '360430', 'city': '彭泽县' },
    { 'code': '360481', 'city': '瑞昌市' },
    { 'code': '360482', 'city': '共青城市' },
    { 'code': '360483', 'city': '庐山市' },
    { 'code': '360500', 'city': '新余市' },
    { 'code': '360501', 'city': '市辖区' },
    { 'code': '360502', 'city': '渝水区' },
    { 'code': '360521', 'city': '分宜县' },
    { 'code': '360600', 'city': '鹰潭市' },
    { 'code': '360601', 'city': '市辖区' },
    { 'code': '360602', 'city': '月湖区' },
    { 'code': '360622', 'city': '余江县' },
    { 'code': '360681', 'city': '贵溪市' },
    { 'code': '360700', 'city': '赣州市' },
    { 'code': '360701', 'city': '市辖区' },
    { 'code': '360702', 'city': '章贡区' },
    { 'code': '360703', 'city': '南康区' },
    { 'code': '360721', 'city': '赣县' },
    { 'code': '360722', 'city': '信丰县' },
    { 'code': '360723', 'city': '大余县' },
    { 'code': '360724', 'city': '上犹县' },
    { 'code': '360725', 'city': '崇义县' },
    { 'code': '360726', 'city': '安远县' },
    { 'code': '360727', 'city': '龙南县' },
    { 'code': '360728', 'city': '定南县' },
    { 'code': '360729', 'city': '全南县' },
    { 'code': '360730', 'city': '宁都县' },
    { 'code': '360731', 'city': '于都县' },
    { 'code': '360732', 'city': '兴国县' },
    { 'code': '360733', 'city': '会昌县' },
    { 'code': '360734', 'city': '寻乌县' },
    { 'code': '360735', 'city': '石城县' },
    { 'code': '360781', 'city': '瑞金市' },
    { 'code': '360800', 'city': '吉安市' },
    { 'code': '360801', 'city': '市辖区' },
    { 'code': '360802', 'city': '吉州区' },
    { 'code': '360803', 'city': '青原区' },
    { 'code': '360821', 'city': '吉安县' },
    { 'code': '360822', 'city': '吉水县' },
    { 'code': '360823', 'city': '峡江县' },
    { 'code': '360824', 'city': '新干县' },
    { 'code': '360825', 'city': '永丰县' },
    { 'code': '360826', 'city': '泰和县' },
    { 'code': '360827', 'city': '遂川县' },
    { 'code': '360828', 'city': '万安县' },
    { 'code': '360829', 'city': '安福县' },
    { 'code': '360830', 'city': '永新县' },
    { 'code': '360881', 'city': '井冈山市' },
    { 'code': '360900', 'city': '宜春市' },
    { 'code': '360901', 'city': '市辖区' },
    { 'code': '360902', 'city': '袁州区' },
    { 'code': '360921', 'city': '奉新县' },
    { 'code': '360922', 'city': '万载县' },
    { 'code': '360923', 'city': '上高县' },
    { 'code': '360924', 'city': '宜丰县' },
    { 'code': '360925', 'city': '靖安县' },
    { 'code': '360926', 'city': '铜鼓县' },
    { 'code': '360981', 'city': '丰城市' },
    { 'code': '360982', 'city': '樟树市' },
    { 'code': '360983', 'city': '高安市' },
    { 'code': '361000', 'city': '抚州市' },
    { 'code': '361001', 'city': '市辖区' },
    { 'code': '361002', 'city': '临川区' },
    { 'code': '361021', 'city': '南城县' },
    { 'code': '361022', 'city': '黎川县' },
    { 'code': '361023', 'city': '南丰县' },
    { 'code': '361024', 'city': '崇仁县' },
    { 'code': '361025', 'city': '乐安县' },
    { 'code': '361026', 'city': '宜黄县' },
    { 'code': '361027', 'city': '金溪县' },
    { 'code': '361028', 'city': '资溪县' },
    { 'code': '361029', 'city': '东乡县' },
    { 'code': '361030', 'city': '广昌县' },
    { 'code': '361100', 'city': '上饶市' },
    { 'code': '361101', 'city': '市辖区' },
    { 'code': '361102', 'city': '信州区' },
    { 'code': '361103', 'city': '广丰区' },
    { 'code': '361121', 'city': '上饶县' },
    { 'code': '361123', 'city': '玉山县' },
    { 'code': '361124', 'city': '铅山县' },
    { 'code': '361125', 'city': '横峰县' },
    { 'code': '361126', 'city': '弋阳县' },
    { 'code': '361127', 'city': '余干县' },
    { 'code': '361128', 'city': '鄱阳县' },
    { 'code': '361129', 'city': '万年县' },
    { 'code': '361130', 'city': '婺源县' },
    { 'code': '361181', 'city': '德兴市' },
    { 'code': '370000', 'city': '山东省' },
    { 'code': '370100', 'city': '济南市' },
    { 'code': '370101', 'city': '市辖区' },
    { 'code': '370102', 'city': '历下区' },
    { 'code': '370103', 'city': '市中区' },
    { 'code': '370104', 'city': '槐荫区' },
    { 'code': '370105', 'city': '天桥区' },
    { 'code': '370112', 'city': '历城区' },
    { 'code': '370113', 'city': '长清区' },
    { 'code': '370124', 'city': '平阴县' },
    { 'code': '370125', 'city': '济阳县' },
    { 'code': '370126', 'city': '商河县' },
    { 'code': '370181', 'city': '章丘市' },
    { 'code': '370200', 'city': '青岛市' },
    { 'code': '370201', 'city': '市辖区' },
    { 'code': '370202', 'city': '市南区' },
    { 'code': '370203', 'city': '市北区' },
    { 'code': '370211', 'city': '黄岛区' },
    { 'code': '370212', 'city': '崂山区' },
    { 'code': '370213', 'city': '李沧区' },
    { 'code': '370214', 'city': '城阳区' },
    { 'code': '370281', 'city': '胶州市' },
    { 'code': '370282', 'city': '即墨市' },
    { 'code': '370283', 'city': '平度市' },
    { 'code': '370285', 'city': '莱西市' },
    { 'code': '370300', 'city': '淄博市' },
    { 'code': '370301', 'city': '市辖区' },
    { 'code': '370302', 'city': '淄川区' },
    { 'code': '370303', 'city': '张店区' },
    { 'code': '370304', 'city': '博山区' },
    { 'code': '370305', 'city': '临淄区' },
    { 'code': '370306', 'city': '周村区' },
    { 'code': '370321', 'city': '桓台县' },
    { 'code': '370322', 'city': '高青县' },
    { 'code': '370323', 'city': '沂源县' },
    { 'code': '370400', 'city': '枣庄市' },
    { 'code': '370401', 'city': '市辖区' },
    { 'code': '370402', 'city': '市中区' },
    { 'code': '370403', 'city': '薛城区' },
    { 'code': '370404', 'city': '峄城区' },
    { 'code': '370405', 'city': '台儿庄区' },
    { 'code': '370406', 'city': '山亭区' },
    { 'code': '370481', 'city': '滕州市' },
    { 'code': '370500', 'city': '东营市' },
    { 'code': '370501', 'city': '市辖区' },
    { 'code': '370502', 'city': '东营区' },
    { 'code': '370503', 'city': '河口区' },
    { 'code': '370505', 'city': '垦利区' },
    { 'code': '370522', 'city': '利津县' },
    { 'code': '370523', 'city': '广饶县' },
    { 'code': '370600', 'city': '烟台市' },
    { 'code': '370601', 'city': '市辖区' },
    { 'code': '370602', 'city': '芝罘区' },
    { 'code': '370611', 'city': '福山区' },
    { 'code': '370612', 'city': '牟平区' },
    { 'code': '370613', 'city': '莱山区' },
    { 'code': '370634', 'city': '长岛县' },
    { 'code': '370681', 'city': '龙口市' },
    { 'code': '370682', 'city': '莱阳市' },
    { 'code': '370683', 'city': '莱州市' },
    { 'code': '370684', 'city': '蓬莱市' },
    { 'code': '370685', 'city': '招远市' },
    { 'code': '370686', 'city': '栖霞市' },
    { 'code': '370687', 'city': '海阳市' },
    { 'code': '370700', 'city': '潍坊市' },
    { 'code': '370701', 'city': '市辖区' },
    { 'code': '370702', 'city': '潍城区' },
    { 'code': '370703', 'city': '寒亭区' },
    { 'code': '370704', 'city': '坊子区' },
    { 'code': '370705', 'city': '奎文区' },
    { 'code': '370724', 'city': '临朐县' },
    { 'code': '370725', 'city': '昌乐县' },
    { 'code': '370781', 'city': '青州市' },
    { 'code': '370782', 'city': '诸城市' },
    { 'code': '370783', 'city': '寿光市' },
    { 'code': '370784', 'city': '安丘市' },
    { 'code': '370785', 'city': '高密市' },
    { 'code': '370786', 'city': '昌邑市' },
    { 'code': '370800', 'city': '济宁市' },
    { 'code': '370801', 'city': '市辖区' },
    { 'code': '370811', 'city': '任城区' },
    { 'code': '370812', 'city': '兖州区' },
    { 'code': '370826', 'city': '微山县' },
    { 'code': '370827', 'city': '鱼台县' },
    { 'code': '370828', 'city': '金乡县' },
    { 'code': '370829', 'city': '嘉祥县' },
    { 'code': '370830', 'city': '汶上县' },
    { 'code': '370831', 'city': '泗水县' },
    { 'code': '370832', 'city': '梁山县' },
    { 'code': '370881', 'city': '曲阜市' },
    { 'code': '370883', 'city': '邹城市' },
    { 'code': '370900', 'city': '泰安市' },
    { 'code': '370901', 'city': '市辖区' },
    { 'code': '370902', 'city': '泰山区' },
    { 'code': '370911', 'city': '岱岳区' },
    { 'code': '370921', 'city': '宁阳县' },
    { 'code': '370923', 'city': '东平县' },
    { 'code': '370982', 'city': '新泰市' },
    { 'code': '370983', 'city': '肥城市' },
    { 'code': '371000', 'city': '威海市' },
    { 'code': '371001', 'city': '市辖区' },
    { 'code': '371002', 'city': '环翠区' },
    { 'code': '371003', 'city': '文登区' },
    { 'code': '371082', 'city': '荣成市' },
    { 'code': '371083', 'city': '乳山市' },
    { 'code': '371100', 'city': '日照市' },
    { 'code': '371101', 'city': '市辖区' },
    { 'code': '371102', 'city': '东港区' },
    { 'code': '371103', 'city': '岚山区' },
    { 'code': '371121', 'city': '五莲县' },
    { 'code': '371122', 'city': '莒县' },
    { 'code': '371200', 'city': '莱芜市' },
    { 'code': '371201', 'city': '市辖区' },
    { 'code': '371202', 'city': '莱城区' },
    { 'code': '371203', 'city': '钢城区' },
    { 'code': '371300', 'city': '临沂市' },
    { 'code': '371301', 'city': '市辖区' },
    { 'code': '371302', 'city': '兰山区' },
    { 'code': '371311', 'city': '罗庄区' },
    { 'code': '371312', 'city': '河东区' },
    { 'code': '371321', 'city': '沂南县' },
    { 'code': '371322', 'city': '郯城县' },
    { 'code': '371323', 'city': '沂水县' },
    { 'code': '371324', 'city': '兰陵县' },
    { 'code': '371325', 'city': '费县' },
    { 'code': '371326', 'city': '平邑县' },
    { 'code': '371327', 'city': '莒南县' },
    { 'code': '371328', 'city': '蒙阴县' },
    { 'code': '371329', 'city': '临沭县' },
    { 'code': '371400', 'city': '德州市' },
    { 'code': '371401', 'city': '市辖区' },
    { 'code': '371402', 'city': '德城区' },
    { 'code': '371403', 'city': '陵城区' },
    { 'code': '371422', 'city': '宁津县' },
    { 'code': '371423', 'city': '庆云县' },
    { 'code': '371424', 'city': '临邑县' },
    { 'code': '371425', 'city': '齐河县' },
    { 'code': '371426', 'city': '平原县' },
    { 'code': '371427', 'city': '夏津县' },
    { 'code': '371428', 'city': '武城县' },
    { 'code': '371481', 'city': '乐陵市' },
    { 'code': '371482', 'city': '禹城市' },
    { 'code': '371500', 'city': '聊城市' },
    { 'code': '371501', 'city': '市辖区' },
    { 'code': '371502', 'city': '东昌府区' },
    { 'code': '371521', 'city': '阳谷县' },
    { 'code': '371522', 'city': '莘县' },
    { 'code': '371523', 'city': '茌平县' },
    { 'code': '371524', 'city': '东阿县' },
    { 'code': '371525', 'city': '冠县' },
    { 'code': '371526', 'city': '高唐县' },
    { 'code': '371581', 'city': '临清市' },
    { 'code': '371600', 'city': '滨州市' },
    { 'code': '371601', 'city': '市辖区' },
    { 'code': '371602', 'city': '滨城区' },
    { 'code': '371603', 'city': '沾化区' },
    { 'code': '371621', 'city': '惠民县' },
    { 'code': '371622', 'city': '阳信县' },
    { 'code': '371623', 'city': '无棣县' },
    { 'code': '371625', 'city': '博兴县' },
    { 'code': '371626', 'city': '邹平县' },
    { 'code': '371700', 'city': '菏泽市' },
    { 'code': '371701', 'city': '市辖区' },
    { 'code': '371702', 'city': '牡丹区' },
    { 'code': '371703', 'city': '定陶区' },
    { 'code': '371721', 'city': '曹县' },
    { 'code': '371722', 'city': '单县' },
    { 'code': '371723', 'city': '成武县' },
    { 'code': '371724', 'city': '巨野县' },
    { 'code': '371725', 'city': '郓城县' },
    { 'code': '371726', 'city': '鄄城县' },
    { 'code': '371728', 'city': '东明县' },
    { 'code': '410000', 'city': '河南省' },
    { 'code': '410100', 'city': '郑州市' },
    { 'code': '410101', 'city': '市辖区' },
    { 'code': '410102', 'city': '中原区' },
    { 'code': '410103', 'city': '二七区' },
    { 'code': '410104', 'city': '管城回族区' },
    { 'code': '410105', 'city': '金水区' },
    { 'code': '410106', 'city': '上街区' },
    { 'code': '410108', 'city': '惠济区' },
    { 'code': '410122', 'city': '中牟县' },
    { 'code': '410181', 'city': '巩义市' },
    { 'code': '410182', 'city': '荥阳市' },
    { 'code': '410183', 'city': '新密市' },
    { 'code': '410184', 'city': '新郑市' },
    { 'code': '410185', 'city': '登封市' },
    { 'code': '410200', 'city': '开封市' },
    { 'code': '410201', 'city': '市辖区' },
    { 'code': '410202', 'city': '龙亭区' },
    { 'code': '410203', 'city': '顺河回族区' },
    { 'code': '410204', 'city': '鼓楼区' },
    { 'code': '410205', 'city': '禹王台区' },
    { 'code': '410211', 'city': '金明区' },
    { 'code': '410212', 'city': '祥符区' },
    { 'code': '410221', 'city': '杞县' },
    { 'code': '410222', 'city': '通许县' },
    { 'code': '410223', 'city': '尉氏县' },
    { 'code': '410225', 'city': '兰考县' },
    { 'code': '410300', 'city': '洛阳市' },
    { 'code': '410301', 'city': '市辖区' },
    { 'code': '410302', 'city': '老城区' },
    { 'code': '410303', 'city': '西工区' },
    { 'code': '410304', 'city': '瀍河回族区' },
    { 'code': '410305', 'city': '涧西区' },
    { 'code': '410306', 'city': '吉利区' },
    { 'code': '410311', 'city': '洛龙区' },
    { 'code': '410322', 'city': '孟津县' },
    { 'code': '410323', 'city': '新安县' },
    { 'code': '410324', 'city': '栾川县' },
    { 'code': '410325', 'city': '嵩县' },
    { 'code': '410326', 'city': '汝阳县' },
    { 'code': '410327', 'city': '宜阳县' },
    { 'code': '410328', 'city': '洛宁县' },
    { 'code': '410329', 'city': '伊川县' },
    { 'code': '410381', 'city': '偃师市' },
    { 'code': '410400', 'city': '平顶山市' },
    { 'code': '410401', 'city': '市辖区' },
    { 'code': '410402', 'city': '新华区' },
    { 'code': '410403', 'city': '卫东区' },
    { 'code': '410404', 'city': '石龙区' },
    { 'code': '410411', 'city': '湛河区' },
    { 'code': '410421', 'city': '宝丰县' },
    { 'code': '410422', 'city': '叶县' },
    { 'code': '410423', 'city': '鲁山县' },
    { 'code': '410425', 'city': '郏县' },
    { 'code': '410481', 'city': '舞钢市' },
    { 'code': '410482', 'city': '汝州市' },
    { 'code': '410471', 'city': '高新区' },
    { 'code': '410472', 'city': '示范区' },
    { 'code': '410500', 'city': '安阳市' },
    { 'code': '410501', 'city': '市辖区' },
    { 'code': '410502', 'city': '文峰区' },
    { 'code': '410503', 'city': '北关区' },
    { 'code': '410505', 'city': '殷都区' },
    { 'code': '410506', 'city': '龙安区' },
    { 'code': '410522', 'city': '安阳县' },
    { 'code': '410523', 'city': '汤阴县' },
    { 'code': '410526', 'city': '滑县' },
    { 'code': '410527', 'city': '内黄县' },
    { 'code': '410581', 'city': '林州市' },
    { 'code': '410600', 'city': '鹤壁市' },
    { 'code': '410601', 'city': '市辖区' },
    { 'code': '410602', 'city': '鹤山区' },
    { 'code': '410603', 'city': '山城区' },
    { 'code': '410611', 'city': '淇滨区' },
    { 'code': '410621', 'city': '浚县' },
    { 'code': '410622', 'city': '淇县' },
    { 'code': '410700', 'city': '新乡市' },
    { 'code': '410701', 'city': '市辖区' },
    { 'code': '410702', 'city': '红旗区' },
    { 'code': '410703', 'city': '卫滨区' },
    { 'code': '410704', 'city': '凤泉区' },
    { 'code': '410711', 'city': '牧野区' },
    { 'code': '410721', 'city': '新乡县' },
    { 'code': '410724', 'city': '获嘉县' },
    { 'code': '410725', 'city': '原阳县' },
    { 'code': '410726', 'city': '延津县' },
    { 'code': '410727', 'city': '封丘县' },
    { 'code': '410728', 'city': '长垣县' },
    { 'code': '410781', 'city': '卫辉市' },
    { 'code': '410782', 'city': '辉县市' },
    { 'code': '410800', 'city': '焦作市' },
    { 'code': '410801', 'city': '市辖区' },
    { 'code': '410802', 'city': '解放区' },
    { 'code': '410803', 'city': '中站区' },
    { 'code': '410804', 'city': '马村区' },
    { 'code': '410811', 'city': '山阳区' },
    { 'code': '410821', 'city': '修武县' },
    { 'code': '410822', 'city': '博爱县' },
    { 'code': '410823', 'city': '武陟县' },
    { 'code': '410825', 'city': '温县' },
    { 'code': '410882', 'city': '沁阳市' },
    { 'code': '410883', 'city': '孟州市' },
    { 'code': '410900', 'city': '濮阳市' },
    { 'code': '410901', 'city': '市辖区' },
    { 'code': '410902', 'city': '华龙区' },
    { 'code': '410922', 'city': '清丰县' },
    { 'code': '410923', 'city': '南乐县' },
    { 'code': '410926', 'city': '范县' },
    { 'code': '410927', 'city': '台前县' },
    { 'code': '410928', 'city': '濮阳县' },
    { 'code': '411000', 'city': '许昌市' },
    { 'code': '411001', 'city': '市辖区' },
    { 'code': '411002', 'city': '魏都区' },
    { 'code': '411023', 'city': '许昌县' },
    { 'code': '411024', 'city': '鄢陵县' },
    { 'code': '411025', 'city': '襄城县' },
    { 'code': '411081', 'city': '禹州市' },
    { 'code': '411082', 'city': '长葛市' },
    { 'code': '411100', 'city': '漯河市' },
    { 'code': '411101', 'city': '市辖区' },
    { 'code': '411102', 'city': '源汇区' },
    { 'code': '411103', 'city': '郾城区' },
    { 'code': '411104', 'city': '召陵区' },
    { 'code': '411121', 'city': '舞阳县' },
    { 'code': '411122', 'city': '临颍县' },
    { 'code': '411200', 'city': '三门峡市' },
    { 'code': '411201', 'city': '市辖区' },
    { 'code': '411202', 'city': '湖滨区' },
    { 'code': '411203', 'city': '陕州区' },
    { 'code': '411221', 'city': '渑池县' },
    { 'code': '411224', 'city': '卢氏县' },
    { 'code': '411281', 'city': '义马市' },
    { 'code': '411282', 'city': '灵宝市' },
    { 'code': '411300', 'city': '南阳市' },
    { 'code': '411301', 'city': '市辖区' },
    { 'code': '411302', 'city': '宛城区' },
    { 'code': '411303', 'city': '卧龙区' },
    { 'code': '411321', 'city': '南召县' },
    { 'code': '411322', 'city': '方城县' },
    { 'code': '411323', 'city': '西峡县' },
    { 'code': '411324', 'city': '镇平县' },
    { 'code': '411325', 'city': '内乡县' },
    { 'code': '411326', 'city': '淅川县' },
    { 'code': '411327', 'city': '社旗县' },
    { 'code': '411328', 'city': '唐河县' },
    { 'code': '411329', 'city': '新野县' },
    { 'code': '411330', 'city': '桐柏县' },
    { 'code': '411381', 'city': '邓州市' },
    { 'code': '411400', 'city': '商丘市' },
    { 'code': '411401', 'city': '市辖区' },
    { 'code': '411402', 'city': '梁园区' },
    { 'code': '411403', 'city': '睢阳区' },
    { 'code': '411421', 'city': '民权县' },
    { 'code': '411422', 'city': '睢县' },
    { 'code': '411423', 'city': '宁陵县' },
    { 'code': '411424', 'city': '柘城县' },
    { 'code': '411425', 'city': '虞城县' },
    { 'code': '411426', 'city': '夏邑县' },
    { 'code': '411481', 'city': '永城市' },
    { 'code': '411500', 'city': '信阳市' },
    { 'code': '411501', 'city': '市辖区' },
    { 'code': '411502', 'city': '浉河区' },
    { 'code': '411503', 'city': '平桥区' },
    { 'code': '411521', 'city': '罗山县' },
    { 'code': '411522', 'city': '光山县' },
    { 'code': '411523', 'city': '新县' },
    { 'code': '411524', 'city': '商城县' },
    { 'code': '411525', 'city': '固始县' },
    { 'code': '411526', 'city': '潢川县' },
    { 'code': '411527', 'city': '淮滨县' },
    { 'code': '411528', 'city': '息县' },
    { 'code': '411600', 'city': '周口市' },
    { 'code': '411601', 'city': '市辖区' },
    { 'code': '411602', 'city': '川汇区' },
    { 'code': '411621', 'city': '扶沟县' },
    { 'code': '411622', 'city': '西华县' },
    { 'code': '411623', 'city': '商水县' },
    { 'code': '411624', 'city': '沈丘县' },
    { 'code': '411625', 'city': '郸城县' },
    { 'code': '411626', 'city': '淮阳县' },
    { 'code': '411627', 'city': '太康县' },
    { 'code': '411628', 'city': '鹿邑县' },
    { 'code': '411681', 'city': '项城市' },
    { 'code': '411700', 'city': '驻马店市' },
    { 'code': '411701', 'city': '市辖区' },
    { 'code': '411702', 'city': '驿城区' },
    { 'code': '411721', 'city': '西平县' },
    { 'code': '411722', 'city': '上蔡县' },
    { 'code': '411723', 'city': '平舆县' },
    { 'code': '411724', 'city': '正阳县' },
    { 'code': '411725', 'city': '确山县' },
    { 'code': '411726', 'city': '泌阳县' },
    { 'code': '411727', 'city': '汝南县' },
    { 'code': '411728', 'city': '遂平县' },
    { 'code': '411729', 'city': '新蔡县' },
    { 'code': '419000', 'city': '省直辖县级行政区划' },
    { 'code': '419001', 'city': '济源市' },
    { 'code': '420000', 'city': '湖北省' },
    { 'code': '420100', 'city': '武汉市' },
    { 'code': '420101', 'city': '市辖区' },
    { 'code': '420102', 'city': '江岸区' },
    { 'code': '420103', 'city': '江汉区' },
    { 'code': '420104', 'city': '硚口区' },
    { 'code': '420105', 'city': '汉阳区' },
    { 'code': '420106', 'city': '武昌区' },
    { 'code': '420107', 'city': '青山区' },
    { 'code': '420111', 'city': '洪山区' },
    { 'code': '420112', 'city': '东西湖区' },
    { 'code': '420113', 'city': '汉南区' },
    { 'code': '420114', 'city': '蔡甸区' },
    { 'code': '420115', 'city': '江夏区' },
    { 'code': '420116', 'city': '黄陂区' },
    { 'code': '420117', 'city': '新洲区' },
    { 'code': '420200', 'city': '黄石市' },
    { 'code': '420201', 'city': '市辖区' },
    { 'code': '420202', 'city': '黄石港区' },
    { 'code': '420203', 'city': '西塞山区' },
    { 'code': '420204', 'city': '下陆区' },
    { 'code': '420205', 'city': '铁山区' },
    { 'code': '420222', 'city': '阳新县' },
    { 'code': '420281', 'city': '大冶市' },
    { 'code': '420300', 'city': '十堰市' },
    { 'code': '420301', 'city': '市辖区' },
    { 'code': '420302', 'city': '茅箭区' },
    { 'code': '420303', 'city': '张湾区' },
    { 'code': '420304', 'city': '郧阳区' },
    { 'code': '420322', 'city': '郧西县' },
    { 'code': '420323', 'city': '竹山县' },
    { 'code': '420324', 'city': '竹溪县' },
    { 'code': '420325', 'city': '房县' },
    { 'code': '420381', 'city': '丹江口市' },
    { 'code': '420500', 'city': '宜昌市' },
    { 'code': '420501', 'city': '市辖区' },
    { 'code': '420502', 'city': '西陵区' },
    { 'code': '420503', 'city': '伍家岗区' },
    { 'code': '420504', 'city': '点军区' },
    { 'code': '420505', 'city': '猇亭区' },
    { 'code': '420506', 'city': '夷陵区' },
    { 'code': '420525', 'city': '远安县' },
    { 'code': '420526', 'city': '兴山县' },
    { 'code': '420527', 'city': '秭归县' },
    { 'code': '420528', 'city': '长阳土家族自治县' },
    { 'code': '420529', 'city': '五峰土家族自治县' },
    { 'code': '420581', 'city': '宜都市' },
    { 'code': '420582', 'city': '当阳市' },
    { 'code': '420583', 'city': '枝江市' },
    { 'code': '420600', 'city': '襄阳市' },
    { 'code': '420601', 'city': '市辖区' },
    { 'code': '420602', 'city': '襄城区' },
    { 'code': '420606', 'city': '樊城区' },
    { 'code': '420607', 'city': '襄州区' },
    { 'code': '420624', 'city': '南漳县' },
    { 'code': '420625', 'city': '谷城县' },
    { 'code': '420626', 'city': '保康县' },
    { 'code': '420682', 'city': '老河口市' },
    { 'code': '420683', 'city': '枣阳市' },
    { 'code': '420684', 'city': '宜城市' },
    { 'code': '420700', 'city': '鄂州市' },
    { 'code': '420701', 'city': '市辖区' },
    { 'code': '420702', 'city': '梁子湖区' },
    { 'code': '420703', 'city': '华容区' },
    { 'code': '420704', 'city': '鄂城区' },
    { 'code': '420800', 'city': '荆门市' },
    { 'code': '420801', 'city': '市辖区' },
    { 'code': '420802', 'city': '东宝区' },
    { 'code': '420804', 'city': '掇刀区' },
    { 'code': '420821', 'city': '京山县' },
    { 'code': '420822', 'city': '沙洋县' },
    { 'code': '420881', 'city': '钟祥市' },
    { 'code': '420900', 'city': '孝感市' },
    { 'code': '420901', 'city': '市辖区' },
    { 'code': '420902', 'city': '孝南区' },
    { 'code': '420921', 'city': '孝昌县' },
    { 'code': '420922', 'city': '大悟县' },
    { 'code': '420923', 'city': '云梦县' },
    { 'code': '420981', 'city': '应城市' },
    { 'code': '420982', 'city': '安陆市' },
    { 'code': '420984', 'city': '汉川市' },
    { 'code': '421000', 'city': '荆州市' },
    { 'code': '421001', 'city': '市辖区' },
    { 'code': '421002', 'city': '沙市区' },
    { 'code': '421003', 'city': '荆州区' },
    { 'code': '421022', 'city': '公安县' },
    { 'code': '421023', 'city': '监利县' },
    { 'code': '421024', 'city': '江陵县' },
    { 'code': '421081', 'city': '石首市' },
    { 'code': '421083', 'city': '洪湖市' },
    { 'code': '421087', 'city': '松滋市' },
    { 'code': '421100', 'city': '黄冈市' },
    { 'code': '421101', 'city': '市辖区' },
    { 'code': '421102', 'city': '黄州区' },
    { 'code': '421121', 'city': '团风县' },
    { 'code': '421122', 'city': '红安县' },
    { 'code': '421123', 'city': '罗田县' },
    { 'code': '421124', 'city': '英山县' },
    { 'code': '421125', 'city': '浠水县' },
    { 'code': '421126', 'city': '蕲春县' },
    { 'code': '421127', 'city': '黄梅县' },
    { 'code': '421181', 'city': '麻城市' },
    { 'code': '421182', 'city': '武穴市' },
    { 'code': '421200', 'city': '咸宁市' },
    { 'code': '421201', 'city': '市辖区' },
    { 'code': '421202', 'city': '咸安区' },
    { 'code': '421221', 'city': '嘉鱼县' },
    { 'code': '421222', 'city': '通城县' },
    { 'code': '421223', 'city': '崇阳县' },
    { 'code': '421224', 'city': '通山县' },
    { 'code': '421281', 'city': '赤壁市' },
    { 'code': '421300', 'city': '随州市' },
    { 'code': '421301', 'city': '市辖区' },
    { 'code': '421303', 'city': '曾都区' },
    { 'code': '421321', 'city': '随县' },
    { 'code': '421381', 'city': '广水市' },
    { 'code': '422800', 'city': '恩施土家族苗族自治州' },
    { 'code': '422801', 'city': '恩施市' },
    { 'code': '422802', 'city': '利川市' },
    { 'code': '422822', 'city': '建始县' },
    { 'code': '422823', 'city': '巴东县' },
    { 'code': '422825', 'city': '宣恩县' },
    { 'code': '422826', 'city': '咸丰县' },
    { 'code': '422827', 'city': '来凤县' },
    { 'code': '422828', 'city': '鹤峰县' },
    { 'code': '429000', 'city': '省直辖县级行政区划' },
    { 'code': '429004', 'city': '仙桃市' },
    { 'code': '429005', 'city': '潜江市' },
    { 'code': '429006', 'city': '天门市' },
    { 'code': '429021', 'city': '神农架林区' },
    { 'code': '430000', 'city': '湖南省' },
    { 'code': '430100', 'city': '长沙市' },
    { 'code': '430101', 'city': '市辖区' },
    { 'code': '430102', 'city': '芙蓉区' },
    { 'code': '430103', 'city': '天心区' },
    { 'code': '430104', 'city': '岳麓区' },
    { 'code': '430105', 'city': '开福区' },
    { 'code': '430111', 'city': '雨花区' },
    { 'code': '430112', 'city': '望城区' },
    { 'code': '430121', 'city': '长沙县' },
    { 'code': '430124', 'city': '宁乡县' },
    { 'code': '430181', 'city': '浏阳市' },
    { 'code': '430200', 'city': '株洲市' },
    { 'code': '430201', 'city': '市辖区' },
    { 'code': '430202', 'city': '荷塘区' },
    { 'code': '430203', 'city': '芦淞区' },
    { 'code': '430204', 'city': '石峰区' },
    { 'code': '430211', 'city': '天元区' },
    { 'code': '430221', 'city': '株洲县' },
    { 'code': '430223', 'city': '攸县' },
    { 'code': '430224', 'city': '茶陵县' },
    { 'code': '430225', 'city': '炎陵县' },
    { 'code': '430281', 'city': '醴陵市' },
    { 'code': '430300', 'city': '湘潭市' },
    { 'code': '430301', 'city': '市辖区' },
    { 'code': '430302', 'city': '雨湖区' },
    { 'code': '430304', 'city': '岳塘区' },
    { 'code': '430321', 'city': '湘潭县' },
    { 'code': '430381', 'city': '湘乡市' },
    { 'code': '430382', 'city': '韶山市' },
    { 'code': '430400', 'city': '衡阳市' },
    { 'code': '430401', 'city': '市辖区' },
    { 'code': '430405', 'city': '珠晖区' },
    { 'code': '430406', 'city': '雁峰区' },
    { 'code': '430407', 'city': '石鼓区' },
    { 'code': '430408', 'city': '蒸湘区' },
    { 'code': '430412', 'city': '南岳区' },
    { 'code': '430421', 'city': '衡阳县' },
    { 'code': '430422', 'city': '衡南县' },
    { 'code': '430423', 'city': '衡山县' },
    { 'code': '430424', 'city': '衡东县' },
    { 'code': '430426', 'city': '祁东县' },
    { 'code': '430481', 'city': '耒阳市' },
    { 'code': '430482', 'city': '常宁市' },
    { 'code': '430500', 'city': '邵阳市' },
    { 'code': '430501', 'city': '市辖区' },
    { 'code': '430502', 'city': '双清区' },
    { 'code': '430503', 'city': '大祥区' },
    { 'code': '430511', 'city': '北塔区' },
    { 'code': '430521', 'city': '邵东县' },
    { 'code': '430522', 'city': '新邵县' },
    { 'code': '430523', 'city': '邵阳县' },
    { 'code': '430524', 'city': '隆回县' },
    { 'code': '430525', 'city': '洞口县' },
    { 'code': '430527', 'city': '绥宁县' },
    { 'code': '430528', 'city': '新宁县' },
    { 'code': '430529', 'city': '城步苗族自治县' },
    { 'code': '430581', 'city': '武冈市' },
    { 'code': '430600', 'city': '岳阳市' },
    { 'code': '430601', 'city': '市辖区' },
    { 'code': '430602', 'city': '岳阳楼区' },
    { 'code': '430603', 'city': '云溪区' },
    { 'code': '430611', 'city': '君山区' },
    { 'code': '430621', 'city': '岳阳县' },
    { 'code': '430623', 'city': '华容县' },
    { 'code': '430624', 'city': '湘阴县' },
    { 'code': '430626', 'city': '平江县' },
    { 'code': '430681', 'city': '汨罗市' },
    { 'code': '430682', 'city': '临湘市' },
    { 'code': '430700', 'city': '常德市' },
    { 'code': '430701', 'city': '市辖区' },
    { 'code': '430702', 'city': '武陵区' },
    { 'code': '430703', 'city': '鼎城区' },
    { 'code': '430721', 'city': '安乡县' },
    { 'code': '430722', 'city': '汉寿县' },
    { 'code': '430723', 'city': '澧县' },
    { 'code': '430724', 'city': '临澧县' },
    { 'code': '430725', 'city': '桃源县' },
    { 'code': '430726', 'city': '石门县' },
    { 'code': '430781', 'city': '津市市' },
    { 'code': '430800', 'city': '张家界市' },
    { 'code': '430801', 'city': '市辖区' },
    { 'code': '430802', 'city': '永定区' },
    { 'code': '430811', 'city': '武陵源区' },
    { 'code': '430821', 'city': '慈利县' },
    { 'code': '430822', 'city': '桑植县' },
    { 'code': '430900', 'city': '益阳市' },
    { 'code': '430901', 'city': '市辖区' },
    { 'code': '430902', 'city': '资阳区' },
    { 'code': '430903', 'city': '赫山区' },
    { 'code': '430921', 'city': '南县' },
    { 'code': '430922', 'city': '桃江县' },
    { 'code': '430923', 'city': '安化县' },
    { 'code': '430981', 'city': '沅江市' },
    { 'code': '431000', 'city': '郴州市' },
    { 'code': '431001', 'city': '市辖区' },
    { 'code': '431002', 'city': '北湖区' },
    { 'code': '431003', 'city': '苏仙区' },
    { 'code': '431021', 'city': '桂阳县' },
    { 'code': '431022', 'city': '宜章县' },
    { 'code': '431023', 'city': '永兴县' },
    { 'code': '431024', 'city': '嘉禾县' },
    { 'code': '431025', 'city': '临武县' },
    { 'code': '431026', 'city': '汝城县' },
    { 'code': '431027', 'city': '桂东县' },
    { 'code': '431028', 'city': '安仁县' },
    { 'code': '431081', 'city': '资兴市' },
    { 'code': '431100', 'city': '永州市' },
    { 'code': '431101', 'city': '市辖区' },
    { 'code': '431102', 'city': '零陵区' },
    { 'code': '431103', 'city': '冷水滩区' },
    { 'code': '431121', 'city': '祁阳县' },
    { 'code': '431122', 'city': '东安县' },
    { 'code': '431123', 'city': '双牌县' },
    { 'code': '431124', 'city': '道县' },
    { 'code': '431125', 'city': '江永县' },
    { 'code': '431126', 'city': '宁远县' },
    { 'code': '431127', 'city': '蓝山县' },
    { 'code': '431128', 'city': '新田县' },
    { 'code': '431129', 'city': '江华瑶族自治县' },
    { 'code': '431200', 'city': '怀化市' },
    { 'code': '431201', 'city': '市辖区' },
    { 'code': '431202', 'city': '鹤城区' },
    { 'code': '431221', 'city': '中方县' },
    { 'code': '431222', 'city': '沅陵县' },
    { 'code': '431223', 'city': '辰溪县' },
    { 'code': '431224', 'city': '溆浦县' },
    { 'code': '431225', 'city': '会同县' },
    { 'code': '431226', 'city': '麻阳苗族自治县' },
    { 'code': '431227', 'city': '新晃侗族自治县' },
    { 'code': '431228', 'city': '芷江侗族自治县' },
    { 'code': '431229', 'city': '靖州苗族侗族自治县' },
    { 'code': '431230', 'city': '通道侗族自治县' },
    { 'code': '431281', 'city': '洪江市' },
    { 'code': '431300', 'city': '娄底市' },
    { 'code': '431301', 'city': '市辖区' },
    { 'code': '431302', 'city': '娄星区' },
    { 'code': '431321', 'city': '双峰县' },
    { 'code': '431322', 'city': '新化县' },
    { 'code': '431381', 'city': '冷水江市' },
    { 'code': '431382', 'city': '涟源市' },
    { 'code': '433100', 'city': '湘西土家族苗族自治州' },
    { 'code': '433101', 'city': '吉首市' },
    { 'code': '433122', 'city': '泸溪县' },
    { 'code': '433123', 'city': '凤凰县' },
    { 'code': '433124', 'city': '花垣县' },
    { 'code': '433125', 'city': '保靖县' },
    { 'code': '433126', 'city': '古丈县' },
    { 'code': '433127', 'city': '永顺县' },
    { 'code': '433130', 'city': '龙山县' },
    { 'code': '440000', 'city': '广东省' },
    { 'code': '440100', 'city': '广州市' },
    { 'code': '440101', 'city': '市辖区' },
    { 'code': '440103', 'city': '荔湾区' },
    { 'code': '440104', 'city': '越秀区' },
    { 'code': '440105', 'city': '海珠区' },
    { 'code': '440106', 'city': '天河区' },
    { 'code': '440111', 'city': '白云区' },
    { 'code': '440112', 'city': '黄埔区' },
    { 'code': '440113', 'city': '番禺区' },
    { 'code': '440114', 'city': '花都区' },
    { 'code': '440115', 'city': '南沙区' },
    { 'code': '440117', 'city': '从化区' },
    { 'code': '440118', 'city': '增城区' },
    { 'code': '440200', 'city': '韶关市' },
    { 'code': '440201', 'city': '市辖区' },
    { 'code': '440203', 'city': '武江区' },
    { 'code': '440204', 'city': '浈江区' },
    { 'code': '440205', 'city': '曲江区' },
    { 'code': '440222', 'city': '始兴县' },
    { 'code': '440224', 'city': '仁化县' },
    { 'code': '440229', 'city': '翁源县' },
    { 'code': '440232', 'city': '乳源瑶族自治县' },
    { 'code': '440233', 'city': '新丰县' },
    { 'code': '440281', 'city': '乐昌市' },
    { 'code': '440282', 'city': '南雄市' },
    { 'code': '440300', 'city': '深圳市' },
    { 'code': '440301', 'city': '市辖区' },
    { 'code': '440303', 'city': '罗湖区' },
    { 'code': '440304', 'city': '福田区' },
    { 'code': '440305', 'city': '南山区' },
    { 'code': '440306', 'city': '宝安区' },
    { 'code': '440307', 'city': '龙岗区' },
    { 'code': '440308', 'city': '盐田区' },
    { 'code': '440400', 'city': '珠海市' },
    { 'code': '440401', 'city': '市辖区' },
    { 'code': '440402', 'city': '香洲区' },
    { 'code': '440403', 'city': '斗门区' },
    { 'code': '440404', 'city': '金湾区' },
    { 'code': '440500', 'city': '汕头市' },
    { 'code': '440501', 'city': '市辖区' },
    { 'code': '440507', 'city': '龙湖区' },
    { 'code': '440511', 'city': '金平区' },
    { 'code': '440512', 'city': '濠江区' },
    { 'code': '440513', 'city': '潮阳区' },
    { 'code': '440514', 'city': '潮南区' },
    { 'code': '440515', 'city': '澄海区' },
    { 'code': '440523', 'city': '南澳县' },
    { 'code': '440600', 'city': '佛山市' },
    { 'code': '440601', 'city': '市辖区' },
    { 'code': '440604', 'city': '禅城区' },
    { 'code': '440605', 'city': '南海区' },
    { 'code': '440606', 'city': '顺德区' },
    { 'code': '440607', 'city': '三水区' },
    { 'code': '440608', 'city': '高明区' },
    { 'code': '440700', 'city': '江门市' },
    { 'code': '440701', 'city': '市辖区' },
    { 'code': '440703', 'city': '蓬江区' },
    { 'code': '440704', 'city': '江海区' },
    { 'code': '440705', 'city': '新会区' },
    { 'code': '440781', 'city': '台山市' },
    { 'code': '440783', 'city': '开平市' },
    { 'code': '440784', 'city': '鹤山市' },
    { 'code': '440785', 'city': '恩平市' },
    { 'code': '440800', 'city': '湛江市' },
    { 'code': '440801', 'city': '市辖区' },
    { 'code': '440802', 'city': '赤坎区' },
    { 'code': '440803', 'city': '霞山区' },
    { 'code': '440804', 'city': '坡头区' },
    { 'code': '440811', 'city': '麻章区' },
    { 'code': '440823', 'city': '遂溪县' },
    { 'code': '440825', 'city': '徐闻县' },
    { 'code': '440881', 'city': '廉江市' },
    { 'code': '440882', 'city': '雷州市' },
    { 'code': '440883', 'city': '吴川市' },
    { 'code': '440900', 'city': '茂名市' },
    { 'code': '440901', 'city': '市辖区' },
    { 'code': '440902', 'city': '茂南区' },
    { 'code': '440904', 'city': '电白区' },
    { 'code': '440981', 'city': '高州市' },
    { 'code': '440982', 'city': '化州市' },
    { 'code': '440983', 'city': '信宜市' },
    { 'code': '441200', 'city': '肇庆市' },
    { 'code': '441201', 'city': '市辖区' },
    { 'code': '441202', 'city': '端州区' },
    { 'code': '441203', 'city': '鼎湖区' },
    { 'code': '441204', 'city': '高要区' },
    { 'code': '441223', 'city': '广宁县' },
    { 'code': '441224', 'city': '怀集县' },
    { 'code': '441225', 'city': '封开县' },
    { 'code': '441226', 'city': '德庆县' },
    { 'code': '441284', 'city': '四会市' },
    { 'code': '441300', 'city': '惠州市' },
    { 'code': '441301', 'city': '市辖区' },
    { 'code': '441302', 'city': '惠城区' },
    { 'code': '441303', 'city': '惠阳区' },
    { 'code': '441322', 'city': '博罗县' },
    { 'code': '441323', 'city': '惠东县' },
    { 'code': '441324', 'city': '龙门县' },
    { 'code': '441400', 'city': '梅州市' },
    { 'code': '441401', 'city': '市辖区' },
    { 'code': '441402', 'city': '梅江区' },
    { 'code': '441403', 'city': '梅县区' },
    { 'code': '441422', 'city': '大埔县' },
    { 'code': '441423', 'city': '丰顺县' },
    { 'code': '441424', 'city': '五华县' },
    { 'code': '441426', 'city': '平远县' },
    { 'code': '441427', 'city': '蕉岭县' },
    { 'code': '441481', 'city': '兴宁市' },
    { 'code': '441500', 'city': '汕尾市' },
    { 'code': '441501', 'city': '市辖区' },
    { 'code': '441502', 'city': '城区' },
    { 'code': '441521', 'city': '海丰县' },
    { 'code': '441523', 'city': '陆河县' },
    { 'code': '441581', 'city': '陆丰市' },
    { 'code': '441600', 'city': '河源市' },
    { 'code': '441601', 'city': '市辖区' },
    { 'code': '441602', 'city': '源城区' },
    { 'code': '441621', 'city': '紫金县' },
    { 'code': '441622', 'city': '龙川县' },
    { 'code': '441623', 'city': '连平县' },
    { 'code': '441624', 'city': '和平县' },
    { 'code': '441625', 'city': '东源县' },
    { 'code': '441700', 'city': '阳江市' },
    { 'code': '441701', 'city': '市辖区' },
    { 'code': '441702', 'city': '江城区' },
    { 'code': '441704', 'city': '阳东区' },
    { 'code': '441721', 'city': '阳西县' },
    { 'code': '441781', 'city': '阳春市' },
    { 'code': '441800', 'city': '清远市' },
    { 'code': '441801', 'city': '市辖区' },
    { 'code': '441802', 'city': '清城区' },
    { 'code': '441803', 'city': '清新区' },
    { 'code': '441821', 'city': '佛冈县' },
    { 'code': '441823', 'city': '阳山县' },
    { 'code': '441825', 'city': '连山壮族瑶族自治县' },
    { 'code': '441826', 'city': '连南瑶族自治县' },
    { 'code': '441881', 'city': '英德市' },
    { 'code': '441882', 'city': '连州市' },
    { 'code': '441900', 'city': '东莞市' },
    { 'code': '442000', 'city': '中山市' },
    { 'code': '445100', 'city': '潮州市' },
    { 'code': '445101', 'city': '市辖区' },
    { 'code': '445102', 'city': '湘桥区' },
    { 'code': '445103', 'city': '潮安区' },
    { 'code': '445122', 'city': '饶平县' },
    { 'code': '445200', 'city': '揭阳市' },
    { 'code': '445201', 'city': '市辖区' },
    { 'code': '445202', 'city': '榕城区' },
    { 'code': '445203', 'city': '揭东区' },
    { 'code': '445222', 'city': '揭西县' },
    { 'code': '445224', 'city': '惠来县' },
    { 'code': '445281', 'city': '普宁市' },
    { 'code': '445300', 'city': '云浮市' },
    { 'code': '445301', 'city': '市辖区' },
    { 'code': '445302', 'city': '云城区' },
    { 'code': '445303', 'city': '云安区' },
    { 'code': '445321', 'city': '新兴县' },
    { 'code': '445322', 'city': '郁南县' },
    { 'code': '445381', 'city': '罗定市' },
    { 'code': '450000', 'city': '广西壮族自治区' },
    { 'code': '450100', 'city': '南宁市' },
    { 'code': '450101', 'city': '市辖区' },
    { 'code': '450102', 'city': '兴宁区' },
    { 'code': '450103', 'city': '青秀区' },
    { 'code': '450105', 'city': '江南区' },
    { 'code': '450107', 'city': '西乡塘区' },
    { 'code': '450108', 'city': '良庆区' },
    { 'code': '450109', 'city': '邕宁区' },
    { 'code': '450110', 'city': '武鸣区' },
    { 'code': '450123', 'city': '隆安县' },
    { 'code': '450124', 'city': '马山县' },
    { 'code': '450125', 'city': '上林县' },
    { 'code': '450126', 'city': '宾阳县' },
    { 'code': '450127', 'city': '横县' },
    { 'code': '450200', 'city': '柳州市' },
    { 'code': '450201', 'city': '市辖区' },
    { 'code': '450202', 'city': '城中区' },
    { 'code': '450203', 'city': '鱼峰区' },
    { 'code': '450204', 'city': '柳南区' },
    { 'code': '450205', 'city': '柳北区' },
    { 'code': '450206', 'city': '柳江区' },
    { 'code': '450222', 'city': '柳城县' },
    { 'code': '450223', 'city': '鹿寨县' },
    { 'code': '450224', 'city': '融安县' },
    { 'code': '450225', 'city': '融水苗族自治县' },
    { 'code': '450226', 'city': '三江侗族自治县' },
    { 'code': '450300', 'city': '桂林市' },
    { 'code': '450301', 'city': '市辖区' },
    { 'code': '450302', 'city': '秀峰区' },
    { 'code': '450303', 'city': '叠彩区' },
    { 'code': '450304', 'city': '象山区' },
    { 'code': '450305', 'city': '七星区' },
    { 'code': '450311', 'city': '雁山区' },
    { 'code': '450312', 'city': '临桂区' },
    { 'code': '450321', 'city': '阳朔县' },
    { 'code': '450323', 'city': '灵川县' },
    { 'code': '450324', 'city': '全州县' },
    { 'code': '450325', 'city': '兴安县' },
    { 'code': '450326', 'city': '永福县' },
    { 'code': '450327', 'city': '灌阳县' },
    { 'code': '450328', 'city': '龙胜各族自治县' },
    { 'code': '450329', 'city': '资源县' },
    { 'code': '450330', 'city': '平乐县' },
    { 'code': '450331', 'city': '荔浦县' },
    { 'code': '450332', 'city': '恭城瑶族自治县' },
    { 'code': '450400', 'city': '梧州市' },
    { 'code': '450401', 'city': '市辖区' },
    { 'code': '450403', 'city': '万秀区' },
    { 'code': '450405', 'city': '长洲区' },
    { 'code': '450406', 'city': '龙圩区' },
    { 'code': '450421', 'city': '苍梧县' },
    { 'code': '450422', 'city': '藤县' },
    { 'code': '450423', 'city': '蒙山县' },
    { 'code': '450481', 'city': '岑溪市' },
    { 'code': '450500', 'city': '北海市' },
    { 'code': '450501', 'city': '市辖区' },
    { 'code': '450502', 'city': '海城区' },
    { 'code': '450503', 'city': '银海区' },
    { 'code': '450512', 'city': '铁山港区' },
    { 'code': '450521', 'city': '合浦县' },
    { 'code': '450600', 'city': '防城港市' },
    { 'code': '450601', 'city': '市辖区' },
    { 'code': '450602', 'city': '港口区' },
    { 'code': '450603', 'city': '防城区' },
    { 'code': '450621', 'city': '上思县' },
    { 'code': '450681', 'city': '东兴市' },
    { 'code': '450700', 'city': '钦州市' },
    { 'code': '450701', 'city': '市辖区' },
    { 'code': '450702', 'city': '钦南区' },
    { 'code': '450703', 'city': '钦北区' },
    { 'code': '450721', 'city': '灵山县' },
    { 'code': '450722', 'city': '浦北县' },
    { 'code': '450800', 'city': '贵港市' },
    { 'code': '450801', 'city': '市辖区' },
    { 'code': '450802', 'city': '港北区' },
    { 'code': '450803', 'city': '港南区' },
    { 'code': '450804', 'city': '覃塘区' },
    { 'code': '450821', 'city': '平南县' },
    { 'code': '450881', 'city': '桂平市' },
    { 'code': '450900', 'city': '玉林市' },
    { 'code': '450901', 'city': '市辖区' },
    { 'code': '450902', 'city': '玉州区' },
    { 'code': '450903', 'city': '福绵区' },
    { 'code': '450921', 'city': '容县' },
    { 'code': '450922', 'city': '陆川县' },
    { 'code': '450923', 'city': '博白县' },
    { 'code': '450924', 'city': '兴业县' },
    { 'code': '450981', 'city': '北流市' },
    { 'code': '451000', 'city': '百色市' },
    { 'code': '451001', 'city': '市辖区' },
    { 'code': '451002', 'city': '右江区' },
    { 'code': '451021', 'city': '田阳县' },
    { 'code': '451022', 'city': '田东县' },
    { 'code': '451023', 'city': '平果县' },
    { 'code': '451024', 'city': '德保县' },
    { 'code': '451026', 'city': '那坡县' },
    { 'code': '451027', 'city': '凌云县' },
    { 'code': '451028', 'city': '乐业县' },
    { 'code': '451029', 'city': '田林县' },
    { 'code': '451030', 'city': '西林县' },
    { 'code': '451031', 'city': '隆林各族自治县' },
    { 'code': '451081', 'city': '靖西市' },
    { 'code': '451100', 'city': '贺州市' },
    { 'code': '451101', 'city': '市辖区' },
    { 'code': '451102', 'city': '八步区' },
    { 'code': '451103', 'city': '平桂区' },
    { 'code': '451121', 'city': '昭平县' },
    { 'code': '451122', 'city': '钟山县' },
    { 'code': '451123', 'city': '富川瑶族自治县' },
    { 'code': '451200', 'city': '河池市' },
    { 'code': '451201', 'city': '市辖区' },
    { 'code': '451202', 'city': '金城江区' },
    { 'code': '451221', 'city': '南丹县' },
    { 'code': '451222', 'city': '天峨县' },
    { 'code': '451223', 'city': '凤山县' },
    { 'code': '451224', 'city': '东兰县' },
    { 'code': '451225', 'city': '罗城仫佬族自治县' },
    { 'code': '451226', 'city': '环江毛南族自治县' },
    { 'code': '451227', 'city': '巴马瑶族自治县' },
    { 'code': '451228', 'city': '都安瑶族自治县' },
    { 'code': '451229', 'city': '大化瑶族自治县' },
    { 'code': '451281', 'city': '宜州市' },
    { 'code': '451300', 'city': '来宾市' },
    { 'code': '451301', 'city': '市辖区' },
    { 'code': '451302', 'city': '兴宾区' },
    { 'code': '451321', 'city': '忻城县' },
    { 'code': '451322', 'city': '象州县' },
    { 'code': '451323', 'city': '武宣县' },
    { 'code': '451324', 'city': '金秀瑶族自治县' },
    { 'code': '451381', 'city': '合山市' },
    { 'code': '451400', 'city': '崇左市' },
    { 'code': '451401', 'city': '市辖区' },
    { 'code': '451402', 'city': '江州区' },
    { 'code': '451421', 'city': '扶绥县' },
    { 'code': '451422', 'city': '宁明县' },
    { 'code': '451423', 'city': '龙州县' },
    { 'code': '451424', 'city': '大新县' },
    { 'code': '451425', 'city': '天等县' },
    { 'code': '451481', 'city': '凭祥市' },
    { 'code': '460000', 'city': '海南省' },
    { 'code': '460100', 'city': '海口市' },
    { 'code': '460101', 'city': '市辖区' },
    { 'code': '460105', 'city': '秀英区' },
    { 'code': '460106', 'city': '龙华区' },
    { 'code': '460107', 'city': '琼山区' },
    { 'code': '460108', 'city': '美兰区' },
    { 'code': '460200', 'city': '三亚市' },
    { 'code': '460201', 'city': '市辖区' },
    { 'code': '460202', 'city': '海棠区' },
    { 'code': '460203', 'city': '吉阳区' },
    { 'code': '460204', 'city': '天涯区' },
    { 'code': '460205', 'city': '崖州区' },
    { 'code': '460300', 'city': '三沙市' },
    { 'code': '460400', 'city': '儋州市' },
    { 'code': '469000', 'city': '省直辖县级行政区划' },
    { 'code': '469001', 'city': '五指山市' },
    { 'code': '469002', 'city': '琼海市' },
    { 'code': '469005', 'city': '文昌市' },
    { 'code': '469006', 'city': '万宁市' },
    { 'code': '469007', 'city': '东方市' },
    { 'code': '469021', 'city': '定安县' },
    { 'code': '469022', 'city': '屯昌县' },
    { 'code': '469023', 'city': '澄迈县' },
    { 'code': '469024', 'city': '临高县' },
    { 'code': '469025', 'city': '白沙黎族自治县' },
    { 'code': '469026', 'city': '昌江黎族自治县' },
    { 'code': '469027', 'city': '乐东黎族自治县' },
    { 'code': '469028', 'city': '陵水黎族自治县' },
    { 'code': '469029', 'city': '保亭黎族苗族自治县' },
    { 'code': '469030', 'city': '琼中黎族苗族自治县' },
    { 'code': '500000', 'city': '重庆市' },
    { 'code': '500100', 'city': '市辖区' },
    { 'code': '500101', 'city': '万州区' },
    { 'code': '500102', 'city': '涪陵区' },
    { 'code': '500103', 'city': '渝中区' },
    { 'code': '500104', 'city': '大渡口区' },
    { 'code': '500105', 'city': '江北区' },
    { 'code': '500106', 'city': '沙坪坝区' },
    { 'code': '500107', 'city': '九龙坡区' },
    { 'code': '500108', 'city': '南岸区' },
    { 'code': '500109', 'city': '北碚区' },
    { 'code': '500110', 'city': '綦江区' },
    { 'code': '500111', 'city': '大足区' },
    { 'code': '500112', 'city': '渝北区' },
    { 'code': '500113', 'city': '巴南区' },
    { 'code': '500114', 'city': '黔江区' },
    { 'code': '500115', 'city': '长寿区' },
    { 'code': '500116', 'city': '江津区' },
    { 'code': '500117', 'city': '合川区' },
    { 'code': '500118', 'city': '永川区' },
    { 'code': '500119', 'city': '南川区' },
    { 'code': '500120', 'city': '璧山区' },
    { 'code': '500151', 'city': '铜梁区' },
    { 'code': '500152', 'city': '潼南区' },
    { 'code': '500153', 'city': '荣昌区' },
    { 'code': '500154', 'city': '开州区' },
    { 'code': '500200', 'city': '县' },
    { 'code': '500228', 'city': '梁平县' },
    { 'code': '500229', 'city': '城口县' },
    { 'code': '500230', 'city': '丰都县' },
    { 'code': '500231', 'city': '垫江县' },
    { 'code': '500232', 'city': '武隆县' },
    { 'code': '500233', 'city': '忠县' },
    { 'code': '500235', 'city': '云阳县' },
    { 'code': '500236', 'city': '奉节县' },
    { 'code': '500237', 'city': '巫山县' },
    { 'code': '500238', 'city': '巫溪县' },
    { 'code': '500240', 'city': '石柱土家族自治县' },
    { 'code': '500241', 'city': '秀山土家族苗族自治县' },
    { 'code': '500242', 'city': '酉阳土家族苗族自治县' },
    { 'code': '500243', 'city': '彭水苗族土家族自治县' },
    { 'code': '510000', 'city': '四川省' },
    { 'code': '510100', 'city': '成都市' },
    { 'code': '510101', 'city': '市辖区' },
    { 'code': '510104', 'city': '锦江区' },
    { 'code': '510105', 'city': '青羊区' },
    { 'code': '510106', 'city': '金牛区' },
    { 'code': '510107', 'city': '武侯区' },
    { 'code': '510108', 'city': '成华区' },
    { 'code': '510112', 'city': '龙泉驿区' },
    { 'code': '510113', 'city': '青白江区' },
    { 'code': '510114', 'city': '新都区' },
    { 'code': '510115', 'city': '温江区' },
    { 'code': '510116', 'city': '双流区' },
    { 'code': '510121', 'city': '金堂县' },
    { 'code': '510124', 'city': '郫县' },
    { 'code': '510129', 'city': '大邑县' },
    { 'code': '510131', 'city': '蒲江县' },
    { 'code': '510132', 'city': '新津县' },
    { 'code': '510181', 'city': '都江堰市' },
    { 'code': '510182', 'city': '彭州市' },
    { 'code': '510183', 'city': '邛崃市' },
    { 'code': '510184', 'city': '崇州市' },
    { 'code': '510185', 'city': '简阳市' },
    { 'code': '510300', 'city': '自贡市' },
    { 'code': '510301', 'city': '市辖区' },
    { 'code': '510302', 'city': '自流井区' },
    { 'code': '510303', 'city': '贡井区' },
    { 'code': '510304', 'city': '大安区' },
    { 'code': '510311', 'city': '沿滩区' },
    { 'code': '510321', 'city': '荣县' },
    { 'code': '510322', 'city': '富顺县' },
    { 'code': '510400', 'city': '攀枝花市' },
    { 'code': '510401', 'city': '市辖区' },
    { 'code': '510402', 'city': '东区' },
    { 'code': '510403', 'city': '西区' },
    { 'code': '510411', 'city': '仁和区' },
    { 'code': '510421', 'city': '米易县' },
    { 'code': '510422', 'city': '盐边县' },
    { 'code': '510500', 'city': '泸州市' },
    { 'code': '510501', 'city': '市辖区' },
    { 'code': '510502', 'city': '江阳区' },
    { 'code': '510503', 'city': '纳溪区' },
    { 'code': '510504', 'city': '龙马潭区' },
    { 'code': '510521', 'city': '泸县' },
    { 'code': '510522', 'city': '合江县' },
    { 'code': '510524', 'city': '叙永县' },
    { 'code': '510525', 'city': '古蔺县' },
    { 'code': '510600', 'city': '德阳市' },
    { 'code': '510601', 'city': '市辖区' },
    { 'code': '510603', 'city': '旌阳区' },
    { 'code': '510623', 'city': '中江县' },
    { 'code': '510626', 'city': '罗江县' },
    { 'code': '510681', 'city': '广汉市' },
    { 'code': '510682', 'city': '什邡市' },
    { 'code': '510683', 'city': '绵竹市' },
    { 'code': '510700', 'city': '绵阳市' },
    { 'code': '510701', 'city': '市辖区' },
    { 'code': '510703', 'city': '涪城区' },
    { 'code': '510704', 'city': '游仙区' },
    { 'code': '510705', 'city': '安州区' },
    { 'code': '510722', 'city': '三台县' },
    { 'code': '510723', 'city': '盐亭县' },
    { 'code': '510725', 'city': '梓潼县' },
    { 'code': '510726', 'city': '北川羌族自治县' },
    { 'code': '510727', 'city': '平武县' },
    { 'code': '510781', 'city': '江油市' },
    { 'code': '510800', 'city': '广元市' },
    { 'code': '510801', 'city': '市辖区' },
    { 'code': '510802', 'city': '利州区' },
    { 'code': '510811', 'city': '昭化区' },
    { 'code': '510812', 'city': '朝天区' },
    { 'code': '510821', 'city': '旺苍县' },
    { 'code': '510822', 'city': '青川县' },
    { 'code': '510823', 'city': '剑阁县' },
    { 'code': '510824', 'city': '苍溪县' },
    { 'code': '510900', 'city': '遂宁市' },
    { 'code': '510901', 'city': '市辖区' },
    { 'code': '510903', 'city': '船山区' },
    { 'code': '510904', 'city': '安居区' },
    { 'code': '510921', 'city': '蓬溪县' },
    { 'code': '510922', 'city': '射洪县' },
    { 'code': '510923', 'city': '大英县' },
    { 'code': '511000', 'city': '内江市' },
    { 'code': '511001', 'city': '市辖区' },
    { 'code': '511002', 'city': '市中区' },
    { 'code': '511011', 'city': '东兴区' },
    { 'code': '511024', 'city': '威远县' },
    { 'code': '511025', 'city': '资中县' },
    { 'code': '511028', 'city': '隆昌县' },
    { 'code': '511100', 'city': '乐山市' },
    { 'code': '511101', 'city': '市辖区' },
    { 'code': '511102', 'city': '市中区' },
    { 'code': '511111', 'city': '沙湾区' },
    { 'code': '511112', 'city': '五通桥区' },
    { 'code': '511113', 'city': '金口河区' },
    { 'code': '511123', 'city': '犍为县' },
    { 'code': '511124', 'city': '井研县' },
    { 'code': '511126', 'city': '夹江县' },
    { 'code': '511129', 'city': '沐川县' },
    { 'code': '511132', 'city': '峨边彝族自治县' },
    { 'code': '511133', 'city': '马边彝族自治县' },
    { 'code': '511181', 'city': '峨眉山市' },
    { 'code': '511300', 'city': '南充市' },
    { 'code': '511301', 'city': '市辖区' },
    { 'code': '511302', 'city': '顺庆区' },
    { 'code': '511303', 'city': '高坪区' },
    { 'code': '511304', 'city': '嘉陵区' },
    { 'code': '511321', 'city': '南部县' },
    { 'code': '511322', 'city': '营山县' },
    { 'code': '511323', 'city': '蓬安县' },
    { 'code': '511324', 'city': '仪陇县' },
    { 'code': '511325', 'city': '西充县' },
    { 'code': '511381', 'city': '阆中市' },
    { 'code': '511400', 'city': '眉山市' },
    { 'code': '511401', 'city': '市辖区' },
    { 'code': '511402', 'city': '东坡区' },
    { 'code': '511403', 'city': '彭山区' },
    { 'code': '511421', 'city': '仁寿县' },
    { 'code': '511423', 'city': '洪雅县' },
    { 'code': '511424', 'city': '丹棱县' },
    { 'code': '511425', 'city': '青神县' },
    { 'code': '511500', 'city': '宜宾市' },
    { 'code': '511501', 'city': '市辖区' },
    { 'code': '511502', 'city': '翠屏区' },
    { 'code': '511503', 'city': '南溪区' },
    { 'code': '511521', 'city': '宜宾县' },
    { 'code': '511523', 'city': '江安县' },
    { 'code': '511524', 'city': '长宁县' },
    { 'code': '511525', 'city': '高县' },
    { 'code': '511526', 'city': '珙县' },
    { 'code': '511527', 'city': '筠连县' },
    { 'code': '511528', 'city': '兴文县' },
    { 'code': '511529', 'city': '屏山县' },
    { 'code': '511600', 'city': '广安市' },
    { 'code': '511601', 'city': '市辖区' },
    { 'code': '511602', 'city': '广安区' },
    { 'code': '511603', 'city': '前锋区' },
    { 'code': '511621', 'city': '岳池县' },
    { 'code': '511622', 'city': '武胜县' },
    { 'code': '511623', 'city': '邻水县' },
    { 'code': '511681', 'city': '华蓥市' },
    { 'code': '511700', 'city': '达州市' },
    { 'code': '511701', 'city': '市辖区' },
    { 'code': '511702', 'city': '通川区' },
    { 'code': '511703', 'city': '达川区' },
    { 'code': '511722', 'city': '宣汉县' },
    { 'code': '511723', 'city': '开江县' },
    { 'code': '511724', 'city': '大竹县' },
    { 'code': '511725', 'city': '渠县' },
    { 'code': '511781', 'city': '万源市' },
    { 'code': '511800', 'city': '雅安市' },
    { 'code': '511801', 'city': '市辖区' },
    { 'code': '511802', 'city': '雨城区' },
    { 'code': '511803', 'city': '名山区' },
    { 'code': '511822', 'city': '荥经县' },
    { 'code': '511823', 'city': '汉源县' },
    { 'code': '511824', 'city': '石棉县' },
    { 'code': '511825', 'city': '天全县' },
    { 'code': '511826', 'city': '芦山县' },
    { 'code': '511827', 'city': '宝兴县' },
    { 'code': '511900', 'city': '巴中市' },
    { 'code': '511901', 'city': '市辖区' },
    { 'code': '511902', 'city': '巴州区' },
    { 'code': '511903', 'city': '恩阳区' },
    { 'code': '511921', 'city': '通江县' },
    { 'code': '511922', 'city': '南江县' },
    { 'code': '511923', 'city': '平昌县' },
    { 'code': '512000', 'city': '资阳市' },
    { 'code': '512001', 'city': '市辖区' },
    { 'code': '512002', 'city': '雁江区' },
    { 'code': '512021', 'city': '安岳县' },
    { 'code': '512022', 'city': '乐至县' },
    { 'code': '513200', 'city': '阿坝藏族羌族自治州' },
    { 'code': '513201', 'city': '马尔康市' },
    { 'code': '513221', 'city': '汶川县' },
    { 'code': '513222', 'city': '理县' },
    { 'code': '513223', 'city': '茂县' },
    { 'code': '513224', 'city': '松潘县' },
    { 'code': '513225', 'city': '九寨沟县' },
    { 'code': '513226', 'city': '金川县' },
    { 'code': '513227', 'city': '小金县' },
    { 'code': '513228', 'city': '黑水县' },
    { 'code': '513230', 'city': '壤塘县' },
    { 'code': '513231', 'city': '阿坝县' },
    { 'code': '513232', 'city': '若尔盖县' },
    { 'code': '513233', 'city': '红原县' },
    { 'code': '513300', 'city': '甘孜藏族自治州' },
    { 'code': '513301', 'city': '康定市' },
    { 'code': '513322', 'city': '泸定县' },
    { 'code': '513323', 'city': '丹巴县' },
    { 'code': '513324', 'city': '九龙县' },
    { 'code': '513325', 'city': '雅江县' },
    { 'code': '513326', 'city': '道孚县' },
    { 'code': '513327', 'city': '炉霍县' },
    { 'code': '513328', 'city': '甘孜县' },
    { 'code': '513329', 'city': '新龙县' },
    { 'code': '513330', 'city': '德格县' },
    { 'code': '513331', 'city': '白玉县' },
    { 'code': '513332', 'city': '石渠县' },
    { 'code': '513333', 'city': '色达县' },
    { 'code': '513334', 'city': '理塘县' },
    { 'code': '513335', 'city': '巴塘县' },
    { 'code': '513336', 'city': '乡城县' },
    { 'code': '513337', 'city': '稻城县' },
    { 'code': '513338', 'city': '得荣县' },
    { 'code': '513400', 'city': '凉山彝族自治州' },
    { 'code': '513401', 'city': '西昌市' },
    { 'code': '513422', 'city': '木里藏族自治县' },
    { 'code': '513423', 'city': '盐源县' },
    { 'code': '513424', 'city': '德昌县' },
    { 'code': '513425', 'city': '会理县' },
    { 'code': '513426', 'city': '会东县' },
    { 'code': '513427', 'city': '宁南县' },
    { 'code': '513428', 'city': '普格县' },
    { 'code': '513429', 'city': '布拖县' },
    { 'code': '513430', 'city': '金阳县' },
    { 'code': '513431', 'city': '昭觉县' },
    { 'code': '513432', 'city': '喜德县' },
    { 'code': '513433', 'city': '冕宁县' },
    { 'code': '513434', 'city': '越西县' },
    { 'code': '513435', 'city': '甘洛县' },
    { 'code': '513436', 'city': '美姑县' },
    { 'code': '513437', 'city': '雷波县' },
    { 'code': '520000', 'city': '贵州省' },
    { 'code': '520100', 'city': '贵阳市' },
    { 'code': '520101', 'city': '市辖区' },
    { 'code': '520102', 'city': '南明区' },
    { 'code': '520103', 'city': '云岩区' },
    { 'code': '520111', 'city': '花溪区' },
    { 'code': '520112', 'city': '乌当区' },
    { 'code': '520113', 'city': '白云区' },
    { 'code': '520115', 'city': '观山湖区' },
    { 'code': '520121', 'city': '开阳县' },
    { 'code': '520122', 'city': '息烽县' },
    { 'code': '520123', 'city': '修文县' },
    { 'code': '520181', 'city': '清镇市' },
    { 'code': '520200', 'city': '六盘水市' },
    { 'code': '520201', 'city': '钟山区' },
    { 'code': '520203', 'city': '六枝特区' },
    { 'code': '520221', 'city': '水城县' },
    { 'code': '520222', 'city': '盘县' },
    { 'code': '520300', 'city': '遵义市' },
    { 'code': '520301', 'city': '市辖区' },
    { 'code': '520302', 'city': '红花岗区' },
    { 'code': '520303', 'city': '汇川区' },
    { 'code': '520304', 'city': '播州区' },
    { 'code': '520322', 'city': '桐梓县' },
    { 'code': '520323', 'city': '绥阳县' },
    { 'code': '520324', 'city': '正安县' },
    { 'code': '520325', 'city': '道真仡佬族苗族自治县' },
    { 'code': '520326', 'city': '务川仡佬族苗族自治县' },
    { 'code': '520327', 'city': '凤冈县' },
    { 'code': '520328', 'city': '湄潭县' },
    { 'code': '520329', 'city': '余庆县' },
    { 'code': '520330', 'city': '习水县' },
    { 'code': '520381', 'city': '赤水市' },
    { 'code': '520382', 'city': '仁怀市' },
    { 'code': '520400', 'city': '安顺市' },
    { 'code': '520401', 'city': '市辖区' },
    { 'code': '520402', 'city': '西秀区' },
    { 'code': '520403', 'city': '平坝区' },
    { 'code': '520422', 'city': '普定县' },
    { 'code': '520423', 'city': '镇宁布依族苗族自治县' },
    { 'code': '520424', 'city': '关岭布依族苗族自治县' },
    { 'code': '520425', 'city': '紫云苗族布依族自治县' },
    { 'code': '520500', 'city': '毕节市' },
    { 'code': '520501', 'city': '市辖区' },
    { 'code': '520502', 'city': '七星关区' },
    { 'code': '520521', 'city': '大方县' },
    { 'code': '520522', 'city': '黔西县' },
    { 'code': '520523', 'city': '金沙县' },
    { 'code': '520524', 'city': '织金县' },
    { 'code': '520525', 'city': '纳雍县' },
    { 'code': '520526', 'city': '威宁彝族回族苗族自治县' },
    { 'code': '520527', 'city': '赫章县' },
    { 'code': '520600', 'city': '铜仁市' },
    { 'code': '520601', 'city': '市辖区' },
    { 'code': '520602', 'city': '碧江区' },
    { 'code': '520603', 'city': '万山区' },
    { 'code': '520621', 'city': '江口县' },
    { 'code': '520622', 'city': '玉屏侗族自治县' },
    { 'code': '520623', 'city': '石阡县' },
    { 'code': '520624', 'city': '思南县' },
    { 'code': '520625', 'city': '印江土家族苗族自治县' },
    { 'code': '520626', 'city': '德江县' },
    { 'code': '520627', 'city': '沿河土家族自治县' },
    { 'code': '520628', 'city': '松桃苗族自治县' },
    { 'code': '522300', 'city': '黔西南布依族苗族自治州' },
    { 'code': '522301', 'city': '兴义市' },
    { 'code': '522322', 'city': '兴仁县' },
    { 'code': '522323', 'city': '普安县' },
    { 'code': '522324', 'city': '晴隆县' },
    { 'code': '522325', 'city': '贞丰县' },
    { 'code': '522326', 'city': '望谟县' },
    { 'code': '522327', 'city': '册亨县' },
    { 'code': '522328', 'city': '安龙县' },
    { 'code': '522600', 'city': '黔东南苗族侗族自治州' },
    { 'code': '522601', 'city': '凯里市' },
    { 'code': '522622', 'city': '黄平县' },
    { 'code': '522623', 'city': '施秉县' },
    { 'code': '522624', 'city': '三穗县' },
    { 'code': '522625', 'city': '镇远县' },
    { 'code': '522626', 'city': '岑巩县' },
    { 'code': '522627', 'city': '天柱县' },
    { 'code': '522628', 'city': '锦屏县' },
    { 'code': '522629', 'city': '剑河县' },
    { 'code': '522630', 'city': '台江县' },
    { 'code': '522631', 'city': '黎平县' },
    { 'code': '522632', 'city': '榕江县' },
    { 'code': '522633', 'city': '从江县' },
    { 'code': '522634', 'city': '雷山县' },
    { 'code': '522635', 'city': '麻江县' },
    { 'code': '522636', 'city': '丹寨县' },
    { 'code': '522700', 'city': '黔南布依族苗族自治州' },
    { 'code': '522701', 'city': '都匀市' },
    { 'code': '522702', 'city': '福泉市' },
    { 'code': '522722', 'city': '荔波县' },
    { 'code': '522723', 'city': '贵定县' },
    { 'code': '522725', 'city': '瓮安县' },
    { 'code': '522726', 'city': '独山县' },
    { 'code': '522727', 'city': '平塘县' },
    { 'code': '522728', 'city': '罗甸县' },
    { 'code': '522729', 'city': '长顺县' },
    { 'code': '522730', 'city': '龙里县' },
    { 'code': '522731', 'city': '惠水县' },
    { 'code': '522732', 'city': '三都水族自治县' },
    { 'code': '530000', 'city': '云南省' },
    { 'code': '530100', 'city': '昆明市' },
    { 'code': '530101', 'city': '市辖区' },
    { 'code': '530102', 'city': '五华区' },
    { 'code': '530103', 'city': '盘龙区' },
    { 'code': '530111', 'city': '官渡区' },
    { 'code': '530112', 'city': '西山区' },
    { 'code': '530113', 'city': '东川区' },
    { 'code': '530114', 'city': '呈贡区' },
    { 'code': '530122', 'city': '晋宁县' },
    { 'code': '530124', 'city': '富民县' },
    { 'code': '530125', 'city': '宜良县' },
    { 'code': '530126', 'city': '石林彝族自治县' },
    { 'code': '530127', 'city': '嵩明县' },
    { 'code': '530128', 'city': '禄劝彝族苗族自治县' },
    { 'code': '530129', 'city': '寻甸回族彝族自治县' },
    { 'code': '530181', 'city': '安宁市' },
    { 'code': '530300', 'city': '曲靖市' },
    { 'code': '530301', 'city': '市辖区' },
    { 'code': '530302', 'city': '麒麟区' },
    { 'code': '530303', 'city': '沾益区' },
    { 'code': '530321', 'city': '马龙县' },
    { 'code': '530322', 'city': '陆良县' },
    { 'code': '530323', 'city': '师宗县' },
    { 'code': '530324', 'city': '罗平县' },
    { 'code': '530325', 'city': '富源县' },
    { 'code': '530326', 'city': '会泽县' },
    { 'code': '530381', 'city': '宣威市' },
    { 'code': '530400', 'city': '玉溪市' },
    { 'code': '530401', 'city': '市辖区' },
    { 'code': '530402', 'city': '红塔区' },
    { 'code': '530403', 'city': '江川区' },
    { 'code': '530422', 'city': '澄江县' },
    { 'code': '530423', 'city': '通海县' },
    { 'code': '530424', 'city': '华宁县' },
    { 'code': '530425', 'city': '易门县' },
    { 'code': '530426', 'city': '峨山彝族自治县' },
    { 'code': '530427', 'city': '新平彝族傣族自治县' },
    { 'code': '530428', 'city': '元江哈尼族彝族傣族自治县' },
    { 'code': '530500', 'city': '保山市' },
    { 'code': '530501', 'city': '市辖区' },
    { 'code': '530502', 'city': '隆阳区' },
    { 'code': '530521', 'city': '施甸县' },
    { 'code': '530523', 'city': '龙陵县' },
    { 'code': '530524', 'city': '昌宁县' },
    { 'code': '530581', 'city': '腾冲市' },
    { 'code': '530600', 'city': '昭通市' },
    { 'code': '530601', 'city': '市辖区' },
    { 'code': '530602', 'city': '昭阳区' },
    { 'code': '530621', 'city': '鲁甸县' },
    { 'code': '530622', 'city': '巧家县' },
    { 'code': '530623', 'city': '盐津县' },
    { 'code': '530624', 'city': '大关县' },
    { 'code': '530625', 'city': '永善县' },
    { 'code': '530626', 'city': '绥江县' },
    { 'code': '530627', 'city': '镇雄县' },
    { 'code': '530628', 'city': '彝良县' },
    { 'code': '530629', 'city': '威信县' },
    { 'code': '530630', 'city': '水富县' },
    { 'code': '530700', 'city': '丽江市' },
    { 'code': '530701', 'city': '市辖区' },
    { 'code': '530702', 'city': '古城区' },
    { 'code': '530721', 'city': '玉龙纳西族自治县' },
    { 'code': '530722', 'city': '永胜县' },
    { 'code': '530723', 'city': '华坪县' },
    { 'code': '530724', 'city': '宁蒗彝族自治县' },
    { 'code': '530800', 'city': '普洱市' },
    { 'code': '530801', 'city': '市辖区' },
    { 'code': '530802', 'city': '思茅区' },
    { 'code': '530821', 'city': '宁洱哈尼族彝族自治县' },
    { 'code': '530822', 'city': '墨江哈尼族自治县' },
    { 'code': '530823', 'city': '景东彝族自治县' },
    { 'code': '530824', 'city': '景谷傣族彝族自治县' },
    { 'code': '530825', 'city': '镇沅彝族哈尼族拉祜族自治县' },
    { 'code': '530826', 'city': '江城哈尼族彝族自治县' },
    { 'code': '530827', 'city': '孟连傣族拉祜族佤族自治县' },
    { 'code': '530828', 'city': '澜沧拉祜族自治县' },
    { 'code': '530829', 'city': '西盟佤族自治县' },
    { 'code': '530900', 'city': '临沧市' },
    { 'code': '530901', 'city': '市辖区' },
    { 'code': '530902', 'city': '临翔区' },
    { 'code': '530921', 'city': '凤庆县' },
    { 'code': '530922', 'city': '云县' },
    { 'code': '530923', 'city': '永德县' },
    { 'code': '530924', 'city': '镇康县' },
    { 'code': '530925', 'city': '双江拉祜族佤族布朗族傣族自治县' },
    { 'code': '530926', 'city': '耿马傣族佤族自治县' },
    { 'code': '530927', 'city': '沧源佤族自治县' },
    { 'code': '532300', 'city': '楚雄彝族自治州' },
    { 'code': '532301', 'city': '楚雄市' },
    { 'code': '532322', 'city': '双柏县' },
    { 'code': '532323', 'city': '牟定县' },
    { 'code': '532324', 'city': '南华县' },
    { 'code': '532325', 'city': '姚安县' },
    { 'code': '532326', 'city': '大姚县' },
    { 'code': '532327', 'city': '永仁县' },
    { 'code': '532328', 'city': '元谋县' },
    { 'code': '532329', 'city': '武定县' },
    { 'code': '532331', 'city': '禄丰县' },
    { 'code': '532500', 'city': '红河哈尼族彝族自治州' },
    { 'code': '532501', 'city': '个旧市' },
    { 'code': '532502', 'city': '开远市' },
    { 'code': '532503', 'city': '蒙自市' },
    { 'code': '532504', 'city': '弥勒市' },
    { 'code': '532523', 'city': '屏边苗族自治县' },
    { 'code': '532524', 'city': '建水县' },
    { 'code': '532525', 'city': '石屏县' },
    { 'code': '532527', 'city': '泸西县' },
    { 'code': '532528', 'city': '元阳县' },
    { 'code': '532529', 'city': '红河县' },
    { 'code': '532530', 'city': '金平苗族瑶族傣族自治县' },
    { 'code': '532531', 'city': '绿春县' },
    { 'code': '532532', 'city': '河口瑶族自治县' },
    { 'code': '532600', 'city': '文山壮族苗族自治州' },
    { 'code': '532601', 'city': '文山市' },
    { 'code': '532622', 'city': '砚山县' },
    { 'code': '532623', 'city': '西畴县' },
    { 'code': '532624', 'city': '麻栗坡县' },
    { 'code': '532625', 'city': '马关县' },
    { 'code': '532626', 'city': '丘北县' },
    { 'code': '532627', 'city': '广南县' },
    { 'code': '532628', 'city': '富宁县' },
    { 'code': '532800', 'city': '西双版纳傣族自治州' },
    { 'code': '532801', 'city': '景洪市' },
    { 'code': '532822', 'city': '勐海县' },
    { 'code': '532823', 'city': '勐腊县' },
    { 'code': '532900', 'city': '大理白族自治州' },
    { 'code': '532901', 'city': '大理市' },
    { 'code': '532922', 'city': '漾濞彝族自治县' },
    { 'code': '532923', 'city': '祥云县' },
    { 'code': '532924', 'city': '宾川县' },
    { 'code': '532925', 'city': '弥渡县' },
    { 'code': '532926', 'city': '南涧彝族自治县' },
    { 'code': '532927', 'city': '巍山彝族回族自治县' },
    { 'code': '532928', 'city': '永平县' },
    { 'code': '532929', 'city': '云龙县' },
    { 'code': '532930', 'city': '洱源县' },
    { 'code': '532931', 'city': '剑川县' },
    { 'code': '532932', 'city': '鹤庆县' },
    { 'code': '533100', 'city': '德宏傣族景颇族自治州' },
    { 'code': '533102', 'city': '瑞丽市' },
    { 'code': '533103', 'city': '芒市' },
    { 'code': '533122', 'city': '梁河县' },
    { 'code': '533123', 'city': '盈江县' },
    { 'code': '533124', 'city': '陇川县' },
    { 'code': '533300', 'city': '怒江傈僳族自治州' },
    { 'code': '533301', 'city': '泸水市' },
    { 'code': '533323', 'city': '福贡县' },
    { 'code': '533324', 'city': '贡山独龙族怒族自治县' },
    { 'code': '533325', 'city': '兰坪白族普米族自治县' },
    { 'code': '533400', 'city': '迪庆藏族自治州' },
    { 'code': '533401', 'city': '香格里拉市' },
    { 'code': '533422', 'city': '德钦县' },
    { 'code': '533423', 'city': '维西傈僳族自治县' },
    { 'code': '540000', 'city': '西藏自治区' },
    { 'code': '540100', 'city': '拉萨市' },
    { 'code': '540101', 'city': '市辖区' },
    { 'code': '540102', 'city': '城关区' },
    { 'code': '540103', 'city': '堆龙德庆区' },
    { 'code': '540121', 'city': '林周县' },
    { 'code': '540122', 'city': '当雄县' },
    { 'code': '540123', 'city': '尼木县' },
    { 'code': '540124', 'city': '曲水县' },
    { 'code': '540126', 'city': '达孜县' },
    { 'code': '540127', 'city': '墨竹工卡县' },
    { 'code': '540200', 'city': '日喀则市' },
    { 'code': '540202', 'city': '桑珠孜区' },
    { 'code': '540221', 'city': '南木林县' },
    { 'code': '540222', 'city': '江孜县' },
    { 'code': '540223', 'city': '定日县' },
    { 'code': '540224', 'city': '萨迦县' },
    { 'code': '540225', 'city': '拉孜县' },
    { 'code': '540226', 'city': '昂仁县' },
    { 'code': '540227', 'city': '谢通门县' },
    { 'code': '540228', 'city': '白朗县' },
    { 'code': '540229', 'city': '仁布县' },
    { 'code': '540230', 'city': '康马县' },
    { 'code': '540231', 'city': '定结县' },
    { 'code': '540232', 'city': '仲巴县' },
    { 'code': '540233', 'city': '亚东县' },
    { 'code': '540234', 'city': '吉隆县' },
    { 'code': '540235', 'city': '聂拉木县' },
    { 'code': '540236', 'city': '萨嘎县' },
    { 'code': '540237', 'city': '岗巴县' },
    { 'code': '540300', 'city': '昌都市' },
    { 'code': '540302', 'city': '卡若区' },
    { 'code': '540321', 'city': '江达县' },
    { 'code': '540322', 'city': '贡觉县' },
    { 'code': '540323', 'city': '类乌齐县' },
    { 'code': '540324', 'city': '丁青县' },
    { 'code': '540325', 'city': '察雅县' },
    { 'code': '540326', 'city': '八宿县' },
    { 'code': '540327', 'city': '左贡县' },
    { 'code': '540328', 'city': '芒康县' },
    { 'code': '540329', 'city': '洛隆县' },
    { 'code': '540330', 'city': '边坝县' },
    { 'code': '540400', 'city': '林芝市' },
    { 'code': '540402', 'city': '巴宜区' },
    { 'code': '540421', 'city': '工布江达县' },
    { 'code': '540422', 'city': '米林县' },
    { 'code': '540423', 'city': '墨脱县' },
    { 'code': '540424', 'city': '波密县' },
    { 'code': '540425', 'city': '察隅县' },
    { 'code': '540426', 'city': '朗县' },
    { 'code': '540500', 'city': '山南市' },
    { 'code': '540501', 'city': '市辖区' },
    { 'code': '540502', 'city': '乃东区' },
    { 'code': '540521', 'city': '扎囊县' },
    { 'code': '540522', 'city': '贡嘎县' },
    { 'code': '540523', 'city': '桑日县' },
    { 'code': '540524', 'city': '琼结县' },
    { 'code': '540525', 'city': '曲松县' },
    { 'code': '540526', 'city': '措美县' },
    { 'code': '540527', 'city': '洛扎县' },
    { 'code': '540528', 'city': '加查县' },
    { 'code': '540529', 'city': '隆子县' },
    { 'code': '540530', 'city': '错那县' },
    { 'code': '540531', 'city': '浪卡子县' },
    { 'code': '542400', 'city': '那曲地区' },
    { 'code': '542421', 'city': '那曲县' },
    { 'code': '542422', 'city': '嘉黎县' },
    { 'code': '542423', 'city': '比如县' },
    { 'code': '542424', 'city': '聂荣县' },
    { 'code': '542425', 'city': '安多县' },
    { 'code': '542426', 'city': '申扎县' },
    { 'code': '542427', 'city': '索县' },
    { 'code': '542428', 'city': '班戈县' },
    { 'code': '542429', 'city': '巴青县' },
    { 'code': '542430', 'city': '尼玛县' },
    { 'code': '542431', 'city': '双湖县' },
    { 'code': '542500', 'city': '阿里地区' },
    { 'code': '542521', 'city': '普兰县' },
    { 'code': '542522', 'city': '札达县' },
    { 'code': '542523', 'city': '噶尔县' },
    { 'code': '542524', 'city': '日土县' },
    { 'code': '542525', 'city': '革吉县' },
    { 'code': '542526', 'city': '改则县' },
    { 'code': '542527', 'city': '措勤县' },
    { 'code': '610000', 'city': '陕西省' },
    { 'code': '610100', 'city': '西安市' },
    { 'code': '610101', 'city': '市辖区' },
    { 'code': '610102', 'city': '新城区' },
    { 'code': '610103', 'city': '碑林区' },
    { 'code': '610104', 'city': '莲湖区' },
    { 'code': '610111', 'city': '灞桥区' },
    { 'code': '610112', 'city': '未央区' },
    { 'code': '610113', 'city': '雁塔区' },
    { 'code': '610114', 'city': '阎良区' },
    { 'code': '610115', 'city': '临潼区' },
    { 'code': '610116', 'city': '长安区' },
    { 'code': '610117', 'city': '高陵区' },
    { 'code': '610122', 'city': '蓝田县' },
    { 'code': '610124', 'city': '周至县' },
    { 'code': '610125', 'city': '户县' },
    { 'code': '610200', 'city': '铜川市' },
    { 'code': '610201', 'city': '市辖区' },
    { 'code': '610202', 'city': '王益区' },
    { 'code': '610203', 'city': '印台区' },
    { 'code': '610204', 'city': '耀州区' },
    { 'code': '610222', 'city': '宜君县' },
    { 'code': '610300', 'city': '宝鸡市' },
    { 'code': '610301', 'city': '市辖区' },
    { 'code': '610302', 'city': '渭滨区' },
    { 'code': '610303', 'city': '金台区' },
    { 'code': '610304', 'city': '陈仓区' },
    { 'code': '610322', 'city': '凤翔县' },
    { 'code': '610323', 'city': '岐山县' },
    { 'code': '610324', 'city': '扶风县' },
    { 'code': '610326', 'city': '眉县' },
    { 'code': '610327', 'city': '陇县' },
    { 'code': '610328', 'city': '千阳县' },
    { 'code': '610329', 'city': '麟游县' },
    { 'code': '610330', 'city': '凤县' },
    { 'code': '610331', 'city': '太白县' },
    { 'code': '610400', 'city': '咸阳市' },
    { 'code': '610401', 'city': '市辖区' },
    { 'code': '610402', 'city': '秦都区' },
    { 'code': '610403', 'city': '杨陵区' },
    { 'code': '610404', 'city': '渭城区' },
    { 'code': '610422', 'city': '三原县' },
    { 'code': '610423', 'city': '泾阳县' },
    { 'code': '610424', 'city': '乾县' },
    { 'code': '610425', 'city': '礼泉县' },
    { 'code': '610426', 'city': '永寿县' },
    { 'code': '610427', 'city': '彬县' },
    { 'code': '610428', 'city': '长武县' },
    { 'code': '610429', 'city': '旬邑县' },
    { 'code': '610430', 'city': '淳化县' },
    { 'code': '610431', 'city': '武功县' },
    { 'code': '610481', 'city': '兴平市' },
    { 'code': '610500', 'city': '渭南市' },
    { 'code': '610501', 'city': '市辖区' },
    { 'code': '610502', 'city': '临渭区' },
    { 'code': '610503', 'city': '华州区' },
    { 'code': '610522', 'city': '潼关县' },
    { 'code': '610523', 'city': '大荔县' },
    { 'code': '610524', 'city': '合阳县' },
    { 'code': '610525', 'city': '澄城县' },
    { 'code': '610526', 'city': '蒲城县' },
    { 'code': '610527', 'city': '白水县' },
    { 'code': '610528', 'city': '富平县' },
    { 'code': '610581', 'city': '韩城市' },
    { 'code': '610582', 'city': '华阴市' },
    { 'code': '610600', 'city': '延安市' },
    { 'code': '610601', 'city': '市辖区' },
    { 'code': '610602', 'city': '宝塔区' },
    { 'code': '610603', 'city': '安塞区' },
    { 'code': '610621', 'city': '延长县' },
    { 'code': '610622', 'city': '延川县' },
    { 'code': '610623', 'city': '子长县' },
    { 'code': '610625', 'city': '志丹县' },
    { 'code': '610626', 'city': '吴起县' },
    { 'code': '610627', 'city': '甘泉县' },
    { 'code': '610628', 'city': '富县' },
    { 'code': '610629', 'city': '洛川县' },
    { 'code': '610630', 'city': '宜川县' },
    { 'code': '610631', 'city': '黄龙县' },
    { 'code': '610632', 'city': '黄陵县' },
    { 'code': '610700', 'city': '汉中市' },
    { 'code': '610701', 'city': '市辖区' },
    { 'code': '610702', 'city': '汉台区' },
    { 'code': '610721', 'city': '南郑县' },
    { 'code': '610722', 'city': '城固县' },
    { 'code': '610723', 'city': '洋县' },
    { 'code': '610724', 'city': '西乡县' },
    { 'code': '610725', 'city': '勉县' },
    { 'code': '610726', 'city': '宁强县' },
    { 'code': '610727', 'city': '略阳县' },
    { 'code': '610728', 'city': '镇巴县' },
    { 'code': '610729', 'city': '留坝县' },
    { 'code': '610730', 'city': '佛坪县' },
    { 'code': '610800', 'city': '榆林市' },
    { 'code': '610801', 'city': '市辖区' },
    { 'code': '610802', 'city': '榆阳区' },
    { 'code': '610803', 'city': '横山区' },
    { 'code': '610821', 'city': '神木县' },
    { 'code': '610822', 'city': '府谷县' },
    { 'code': '610824', 'city': '靖边县' },
    { 'code': '610825', 'city': '定边县' },
    { 'code': '610826', 'city': '绥德县' },
    { 'code': '610827', 'city': '米脂县' },
    { 'code': '610828', 'city': '佳县' },
    { 'code': '610829', 'city': '吴堡县' },
    { 'code': '610830', 'city': '清涧县' },
    { 'code': '610831', 'city': '子洲县' },
    { 'code': '610900', 'city': '安康市' },
    { 'code': '610901', 'city': '市辖区' },
    { 'code': '610902', 'city': '汉滨区' },
    { 'code': '610921', 'city': '汉阴县' },
    { 'code': '610922', 'city': '石泉县' },
    { 'code': '610923', 'city': '宁陕县' },
    { 'code': '610924', 'city': '紫阳县' },
    { 'code': '610925', 'city': '岚皋县' },
    { 'code': '610926', 'city': '平利县' },
    { 'code': '610927', 'city': '镇坪县' },
    { 'code': '610928', 'city': '旬阳县' },
    { 'code': '610929', 'city': '白河县' },
    { 'code': '611000', 'city': '商洛市' },
    { 'code': '611001', 'city': '市辖区' },
    { 'code': '611002', 'city': '商州区' },
    { 'code': '611021', 'city': '洛南县' },
    { 'code': '611022', 'city': '丹凤县' },
    { 'code': '611023', 'city': '商南县' },
    { 'code': '611024', 'city': '山阳县' },
    { 'code': '611025', 'city': '镇安县' },
    { 'code': '611026', 'city': '柞水县' },
    { 'code': '620000', 'city': '甘肃省' },
    { 'code': '620100', 'city': '兰州市' },
    { 'code': '620101', 'city': '市辖区' },
    { 'code': '620102', 'city': '城关区' },
    { 'code': '620103', 'city': '七里河区' },
    { 'code': '620104', 'city': '西固区' },
    { 'code': '620105', 'city': '安宁区' },
    { 'code': '620111', 'city': '红古区' },
    { 'code': '620121', 'city': '永登县' },
    { 'code': '620122', 'city': '皋兰县' },
    { 'code': '620123', 'city': '榆中县' },
    { 'code': '620200', 'city': '嘉峪关市' },
    { 'code': '620201', 'city': '市辖区' },
    { 'code': '620300', 'city': '金昌市' },
    { 'code': '620301', 'city': '市辖区' },
    { 'code': '620302', 'city': '金川区' },
    { 'code': '620321', 'city': '永昌县' },
    { 'code': '620400', 'city': '白银市' },
    { 'code': '620401', 'city': '市辖区' },
    { 'code': '620402', 'city': '白银区' },
    { 'code': '620403', 'city': '平川区' },
    { 'code': '620421', 'city': '靖远县' },
    { 'code': '620422', 'city': '会宁县' },
    { 'code': '620423', 'city': '景泰县' },
    { 'code': '620500', 'city': '天水市' },
    { 'code': '620501', 'city': '市辖区' },
    { 'code': '620502', 'city': '秦州区' },
    { 'code': '620503', 'city': '麦积区' },
    { 'code': '620521', 'city': '清水县' },
    { 'code': '620522', 'city': '秦安县' },
    { 'code': '620523', 'city': '甘谷县' },
    { 'code': '620524', 'city': '武山县' },
    { 'code': '620525', 'city': '张家川回族自治县' },
    { 'code': '620600', 'city': '武威市' },
    { 'code': '620601', 'city': '市辖区' },
    { 'code': '620602', 'city': '凉州区' },
    { 'code': '620621', 'city': '民勤县' },
    { 'code': '620622', 'city': '古浪县' },
    { 'code': '620623', 'city': '天祝藏族自治县' },
    { 'code': '620700', 'city': '张掖市' },
    { 'code': '620701', 'city': '市辖区' },
    { 'code': '620702', 'city': '甘州区' },
    { 'code': '620721', 'city': '肃南裕固族自治县' },
    { 'code': '620722', 'city': '民乐县' },
    { 'code': '620723', 'city': '临泽县' },
    { 'code': '620724', 'city': '高台县' },
    { 'code': '620725', 'city': '山丹县' },
    { 'code': '620800', 'city': '平凉市' },
    { 'code': '620801', 'city': '市辖区' },
    { 'code': '620802', 'city': '崆峒区' },
    { 'code': '620821', 'city': '泾川县' },
    { 'code': '620822', 'city': '灵台县' },
    { 'code': '620823', 'city': '崇信县' },
    { 'code': '620824', 'city': '华亭县' },
    { 'code': '620825', 'city': '庄浪县' },
    { 'code': '620826', 'city': '静宁县' },
    { 'code': '620900', 'city': '酒泉市' },
    { 'code': '620901', 'city': '市辖区' },
    { 'code': '620902', 'city': '肃州区' },
    { 'code': '620921', 'city': '金塔县' },
    { 'code': '620922', 'city': '瓜州县' },
    { 'code': '620923', 'city': '肃北蒙古族自治县' },
    { 'code': '620924', 'city': '阿克塞哈萨克族自治县' },
    { 'code': '620981', 'city': '玉门市' },
    { 'code': '620982', 'city': '敦煌市' },
    { 'code': '621000', 'city': '庆阳市' },
    { 'code': '621001', 'city': '市辖区' },
    { 'code': '621002', 'city': '西峰区' },
    { 'code': '621021', 'city': '庆城县' },
    { 'code': '621022', 'city': '环县' },
    { 'code': '621023', 'city': '华池县' },
    { 'code': '621024', 'city': '合水县' },
    { 'code': '621025', 'city': '正宁县' },
    { 'code': '621026', 'city': '宁县' },
    { 'code': '621027', 'city': '镇原县' },
    { 'code': '621100', 'city': '定西市' },
    { 'code': '621101', 'city': '市辖区' },
    { 'code': '621102', 'city': '安定区' },
    { 'code': '621121', 'city': '通渭县' },
    { 'code': '621122', 'city': '陇西县' },
    { 'code': '621123', 'city': '渭源县' },
    { 'code': '621124', 'city': '临洮县' },
    { 'code': '621125', 'city': '漳县' },
    { 'code': '621126', 'city': '岷县' },
    { 'code': '621200', 'city': '陇南市' },
    { 'code': '621201', 'city': '市辖区' },
    { 'code': '621202', 'city': '武都区' },
    { 'code': '621221', 'city': '成县' },
    { 'code': '621222', 'city': '文县' },
    { 'code': '621223', 'city': '宕昌县' },
    { 'code': '621224', 'city': '康县' },
    { 'code': '621225', 'city': '西和县' },
    { 'code': '621226', 'city': '礼县' },
    { 'code': '621227', 'city': '徽县' },
    { 'code': '621228', 'city': '两当县' },
    { 'code': '622900', 'city': '临夏回族自治州' },
    { 'code': '622901', 'city': '临夏市' },
    { 'code': '622921', 'city': '临夏县' },
    { 'code': '622922', 'city': '康乐县' },
    { 'code': '622923', 'city': '永靖县' },
    { 'code': '622924', 'city': '广河县' },
    { 'code': '622925', 'city': '和政县' },
    { 'code': '622926', 'city': '东乡族自治县' },
    { 'code': '622927', 'city': '积石山保安族东乡族撒拉族自治县' },
    { 'code': '623000', 'city': '甘南藏族自治州' },
    { 'code': '623001', 'city': '合作市' },
    { 'code': '623021', 'city': '临潭县' },
    { 'code': '623022', 'city': '卓尼县' },
    { 'code': '623023', 'city': '舟曲县' },
    { 'code': '623024', 'city': '迭部县' },
    { 'code': '623025', 'city': '玛曲县' },
    { 'code': '623026', 'city': '碌曲县' },
    { 'code': '623027', 'city': '夏河县' },
    { 'code': '630000', 'city': '青海省' },
    { 'code': '630100', 'city': '西宁市' },
    { 'code': '630101', 'city': '市辖区' },
    { 'code': '630102', 'city': '城东区' },
    { 'code': '630103', 'city': '城中区' },
    { 'code': '630104', 'city': '城西区' },
    { 'code': '630105', 'city': '城北区' },
    { 'code': '630121', 'city': '大通回族土族自治县' },
    { 'code': '630122', 'city': '湟中县' },
    { 'code': '630123', 'city': '湟源县' },
    { 'code': '630200', 'city': '海东市' },
    { 'code': '630202', 'city': '乐都区' },
    { 'code': '630203', 'city': '平安区' },
    { 'code': '630222', 'city': '民和回族土族自治县' },
    { 'code': '630223', 'city': '互助土族自治县' },
    { 'code': '630224', 'city': '化隆回族自治县' },
    { 'code': '630225', 'city': '循化撒拉族自治县' },
    { 'code': '632200', 'city': '海北藏族自治州' },
    { 'code': '632221', 'city': '门源回族自治县' },
    { 'code': '632222', 'city': '祁连县' },
    { 'code': '632223', 'city': '海晏县' },
    { 'code': '632224', 'city': '刚察县' },
    { 'code': '632300', 'city': '黄南藏族自治州' },
    { 'code': '632321', 'city': '同仁县' },
    { 'code': '632322', 'city': '尖扎县' },
    { 'code': '632323', 'city': '泽库县' },
    { 'code': '632324', 'city': '河南蒙古族自治县' },
    { 'code': '632500', 'city': '海南藏族自治州' },
    { 'code': '632521', 'city': '共和县' },
    { 'code': '632522', 'city': '同德县' },
    { 'code': '632523', 'city': '贵德县' },
    { 'code': '632524', 'city': '兴海县' },
    { 'code': '632525', 'city': '贵南县' },
    { 'code': '632600', 'city': '果洛藏族自治州' },
    { 'code': '632621', 'city': '玛沁县' },
    { 'code': '632622', 'city': '班玛县' },
    { 'code': '632623', 'city': '甘德县' },
    { 'code': '632624', 'city': '达日县' },
    { 'code': '632625', 'city': '久治县' },
    { 'code': '632626', 'city': '玛多县' },
    { 'code': '632700', 'city': '玉树藏族自治州' },
    { 'code': '632701', 'city': '玉树市' },
    { 'code': '632722', 'city': '杂多县' },
    { 'code': '632723', 'city': '称多县' },
    { 'code': '632724', 'city': '治多县' },
    { 'code': '632725', 'city': '囊谦县' },
    { 'code': '632726', 'city': '曲麻莱县' },
    { 'code': '632800', 'city': '海西蒙古族藏族自治州' },
    { 'code': '632801', 'city': '格尔木市' },
    { 'code': '632802', 'city': '德令哈市' },
    { 'code': '632821', 'city': '乌兰县' },
    { 'code': '632822', 'city': '都兰县' },
    { 'code': '632823', 'city': '天峻县' },
    { 'code': '640000', 'city': '宁夏回族自治区' },
    { 'code': '640100', 'city': '银川市' },
    { 'code': '640101', 'city': '市辖区' },
    { 'code': '640104', 'city': '兴庆区' },
    { 'code': '640105', 'city': '西夏区' },
    { 'code': '640106', 'city': '金凤区' },
    { 'code': '640121', 'city': '永宁县' },
    { 'code': '640122', 'city': '贺兰县' },
    { 'code': '640181', 'city': '灵武市' },
    { 'code': '640200', 'city': '石嘴山市' },
    { 'code': '640201', 'city': '市辖区' },
    { 'code': '640202', 'city': '大武口区' },
    { 'code': '640205', 'city': '惠农区' },
    { 'code': '640221', 'city': '平罗县' },
    { 'code': '640300', 'city': '吴忠市' },
    { 'code': '640301', 'city': '市辖区' },
    { 'code': '640302', 'city': '利通区' },
    { 'code': '640303', 'city': '红寺堡区' },
    { 'code': '640323', 'city': '盐池县' },
    { 'code': '640324', 'city': '同心县' },
    { 'code': '640381', 'city': '青铜峡市' },
    { 'code': '640400', 'city': '固原市' },
    { 'code': '640401', 'city': '市辖区' },
    { 'code': '640402', 'city': '原州区' },
    { 'code': '640422', 'city': '西吉县' },
    { 'code': '640423', 'city': '隆德县' },
    { 'code': '640424', 'city': '泾源县' },
    { 'code': '640425', 'city': '彭阳县' },
    { 'code': '640500', 'city': '中卫市' },
    { 'code': '640501', 'city': '市辖区' },
    { 'code': '640502', 'city': '沙坡头区' },
    { 'code': '640521', 'city': '中宁县' },
    { 'code': '640522', 'city': '海原县' },
    { 'code': '650000', 'city': '新疆维吾尔自治区' },
    { 'code': '650100', 'city': '乌鲁木齐市' },
    { 'code': '650101', 'city': '市辖区' },
    { 'code': '650102', 'city': '天山区' },
    { 'code': '650103', 'city': '沙依巴克区' },
    { 'code': '650104', 'city': '新市区' },
    { 'code': '650105', 'city': '水磨沟区' },
    { 'code': '650106', 'city': '头屯河区' },
    { 'code': '650107', 'city': '达坂城区' },
    { 'code': '650109', 'city': '米东区' },
    { 'code': '650121', 'city': '乌鲁木齐县' },
    { 'code': '650200', 'city': '克拉玛依市' },
    { 'code': '650201', 'city': '市辖区' },
    { 'code': '650202', 'city': '独山子区' },
    { 'code': '650203', 'city': '克拉玛依区' },
    { 'code': '650204', 'city': '白碱滩区' },
    { 'code': '650205', 'city': '乌尔禾区' },
    { 'code': '650400', 'city': '吐鲁番市' },
    { 'code': '650402', 'city': '高昌区' },
    { 'code': '650421', 'city': '鄯善县' },
    { 'code': '650422', 'city': '托克逊县' },
    { 'code': '650500', 'city': '哈密市' },
    { 'code': '650502', 'city': '伊州区' },
    { 'code': '650521', 'city': '巴里坤哈萨克自治县' },
    { 'code': '650522', 'city': '伊吾县' },
    { 'code': '652300', 'city': '昌吉回族自治州' },
    { 'code': '652301', 'city': '昌吉市' },
    { 'code': '652302', 'city': '阜康市' },
    { 'code': '652323', 'city': '呼图壁县' },
    { 'code': '652324', 'city': '玛纳斯县' },
    { 'code': '652325', 'city': '奇台县' },
    { 'code': '652327', 'city': '吉木萨尔县' },
    { 'code': '652328', 'city': '木垒哈萨克自治县' },
    { 'code': '652700', 'city': '博尔塔拉蒙古自治州' },
    { 'code': '652701', 'city': '博乐市' },
    { 'code': '652702', 'city': '阿拉山口市' },
    { 'code': '652722', 'city': '精河县' },
    { 'code': '652723', 'city': '温泉县' },
    { 'code': '652800', 'city': '巴音郭楞蒙古自治州' },
    { 'code': '652801', 'city': '库尔勒市' },
    { 'code': '652822', 'city': '轮台县' },
    { 'code': '652823', 'city': '尉犁县' },
    { 'code': '652824', 'city': '若羌县' },
    { 'code': '652825', 'city': '且末县' },
    { 'code': '652826', 'city': '焉耆回族自治县' },
    { 'code': '652827', 'city': '和静县' },
    { 'code': '652828', 'city': '和硕县' },
    { 'code': '652829', 'city': '博湖县' },
    { 'code': '652900', 'city': '阿克苏地区' },
    { 'code': '652901', 'city': '阿克苏市' },
    { 'code': '652922', 'city': '温宿县' },
    { 'code': '652923', 'city': '库车县' },
    { 'code': '652924', 'city': '沙雅县' },
    { 'code': '652925', 'city': '新和县' },
    { 'code': '652926', 'city': '拜城县' },
    { 'code': '652927', 'city': '乌什县' },
    { 'code': '652928', 'city': '阿瓦提县' },
    { 'code': '652929', 'city': '柯坪县' },
    { 'code': '653000', 'city': '克孜勒苏柯尔克孜自治州' },
    { 'code': '653001', 'city': '阿图什市' },
    { 'code': '653022', 'city': '阿克陶县' },
    { 'code': '653023', 'city': '阿合奇县' },
    { 'code': '653024', 'city': '乌恰县' },
    { 'code': '653100', 'city': '喀什地区' },
    { 'code': '653101', 'city': '喀什市' },
    { 'code': '653121', 'city': '疏附县' },
    { 'code': '653122', 'city': '疏勒县' },
    { 'code': '653123', 'city': '英吉沙县' },
    { 'code': '653124', 'city': '泽普县' },
    { 'code': '653125', 'city': '莎车县' },
    { 'code': '653126', 'city': '叶城县' },
    { 'code': '653127', 'city': '麦盖提县' },
    { 'code': '653128', 'city': '岳普湖县' },
    { 'code': '653129', 'city': '伽师县' },
    { 'code': '653130', 'city': '巴楚县' },
    { 'code': '653131', 'city': '塔什库尔干塔吉克自治县' },
    { 'code': '653200', 'city': '和田地区' },
    { 'code': '653201', 'city': '和田市' },
    { 'code': '653221', 'city': '和田县' },
    { 'code': '653222', 'city': '墨玉县' },
    { 'code': '653223', 'city': '皮山县' },
    { 'code': '653224', 'city': '洛浦县' },
    { 'code': '653225', 'city': '策勒县' },
    { 'code': '653226', 'city': '于田县' },
    { 'code': '653227', 'city': '民丰县' },
    { 'code': '654000', 'city': '伊犁哈萨克自治州' },
    { 'code': '654002', 'city': '伊宁市' },
    { 'code': '654003', 'city': '奎屯市' },
    { 'code': '654004', 'city': '霍尔果斯市' },
    { 'code': '654021', 'city': '伊宁县' },
    { 'code': '654022', 'city': '察布查尔锡伯自治县' },
    { 'code': '654023', 'city': '霍城县' },
    { 'code': '654024', 'city': '巩留县' },
    { 'code': '654025', 'city': '新源县' },
    { 'code': '654026', 'city': '昭苏县' },
    { 'code': '654027', 'city': '特克斯县' },
    { 'code': '654028', 'city': '尼勒克县' },
    { 'code': '654200', 'city': '塔城地区' },
    { 'code': '654201', 'city': '塔城市' },
    { 'code': '654202', 'city': '乌苏市' },
    { 'code': '654221', 'city': '额敏县' },
    { 'code': '654223', 'city': '沙湾县' },
    { 'code': '654224', 'city': '托里县' },
    { 'code': '654225', 'city': '裕民县' },
    { 'code': '654226', 'city': '和布克赛尔蒙古自治县' },
    { 'code': '654300', 'city': '阿勒泰地区' },
    { 'code': '654301', 'city': '阿勒泰市' },
    { 'code': '654321', 'city': '布尔津县' },
    { 'code': '654322', 'city': '富蕴县' },
    { 'code': '654323', 'city': '福海县' },
    { 'code': '654324', 'city': '哈巴河县' },
    { 'code': '654325', 'city': '青河县' },
    { 'code': '654326', 'city': '吉木乃县' },
    { 'code': '659000', 'city': '自治区直辖县级行政区划' },
    { 'code': '659001', 'city': '石河子市' },
    { 'code': '659002', 'city': '阿拉尔市' },
    { 'code': '659003', 'city': '图木舒克市' },
    { 'code': '659004', 'city': '五家渠市' },
    { 'code': '659006', 'city': '铁门关市' },
    { 'code': '710000', 'city': '台湾省' },
    { 'code': '810000', 'city': '香港特别行政区' },
    { 'code': '820000', 'city': '澳门特别行政区' }
]

export default cities
